@charset "UTF-8";
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
[v-cloak] {
  display: none; }

@media screen and (min-width: 769px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #222;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: "Open Sans","Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .global-swim {
    display: none; } }
  @media screen and (min-width: 769px) and (max-width: 1220px) {
    .global-swim {
      display: block; } }

@media screen and (min-width: 769px) {
  .clearfix:after, .MODULE .acc-data:after {
    content: "";
    display: block;
    clear: both; }
  .displayMap {
    width: 1120px;
    margin: 0 auto; } }
  @media screen and (min-width: 769px) and (max-width: 1220px) {
    .displayMap {
      width: 90%;
      margin: 0 auto; } }

@media screen and (min-width: 769px) {
  .display {
    max-width: 1096px;
    margin: 0 auto;
    position: relative;
    top: 0;
    padding: 24px;
    left: 0; } }
  @media screen and (min-width: 769px) and (max-width: 768px) {
    .display {
      width: 90%; } }

@media screen and (min-width: 769px) {
    .display.side {
      padding: 0 40px; } }
    @media screen and (min-width: 769px) and (max-width: 768px) {
      .display.side {
        padding: 0; } }

@media screen and (min-width: 769px) {
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #222;
    background: #FFF;
    font-size: 12px;
    line-height: 1.8;
    font-family: "Open Sans","Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .global-swim {
    display: none; } }
  @media screen and (max-width: 768px) and (max-width: 1220px) {
    .global-swim {
      display: block; } }

@media screen and (max-width: 768px) {
  .clearfix:after, .MODULE .acc-data:after {
    content: "";
    display: block;
    clear: both; }
  .displayMap {
    width: auto;
    margin: 0 auto; } }
  @media screen and (max-width: 768px) and (max-width: 1220px) {
    .displayMap {
      width: 90%;
      margin: 0 auto; } }

@media screen and (max-width: 768px) {
  .display {
    max-width: auto-24px;
    margin: 0 auto;
    position: relative;
    top: 0;
    padding: 24px;
    left: 0; } }
  @media screen and (max-width: 768px) and (max-width: 768px) {
    .display {
      width: 90%; } }

@media screen and (max-width: 768px) {
    .display.side {
      padding: 0 40px; } }
    @media screen and (max-width: 768px) and (max-width: 768px) {
      .display.side {
        padding: 0; } }

@media screen and (max-width: 768px) {
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 1220px) {
  .res i.circle {
    width: 94px;
    height: 94px;
    position: fixed;
    top: 16px;
    right: 24px;
    z-index: 100;
    top: 32px;
    right: 32px;
    border: #df1214 1px solid;
    background: #df1214 url(../../../images/common/swim_nav_border.png) no-repeat center;
    background-size: 46px;
    border-radius: 50%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.32);
    cursor: pointer;
    -webkit-transition: all 160ms 0ms ease-in-out;
    -moz-transition: all 160ms 0ms ease-in-out;
    -ms-transition: all 160ms 0ms ease-in-out;
    -o-transition: all 160ms 0ms ease-in-out;
    transition: all 160ms 0ms ease-in-out; } }
  @media screen and (max-width: 1220px) and (max-width: 768px) {
    .res i.circle {
      width: 80px;
      height: 80px; } }

@media screen and (max-width: 1220px) {
    .res i.circle.cross {
      background: #df1214 url(../../../images/common/swim_nav_border_cross.png) no-repeat center; }
    .res i.circle:hover {
      transform: scale(1.2);
      border: rgba(255, 255, 255, 0.64) 8px solid;
      background-size: 32px; }
  .res .global-swim {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.96);
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    .res .global-swim ul li {
      margin: 0 0 24px;
      text-align: center; }
      .res .global-swim ul li:last-child {
        margin: 0; }
      .res .global-swim ul li a {
        color: #222;
        font-size: 32px;
        font-weight: 700;
        line-height: 1; }
        .res .global-swim ul li a:before {
          content: attr(data-eng);
          font-size: 11px;
          color: #df1214;
          display: block;
          letter-spacing: .12em;
          margin: 0 0 12px; }
        .res .global-swim ul li a:hover {
          opacity: .64; }
  .res-recruit {
    display: none; } }
  @media screen and (max-width: 1220px) and (max-width: 1220px) {
    .res-recruit {
      display: block; } }

@media screen and (max-width: 1220px) {
    .res-recruit i.circle {
      width: 94px;
      height: 94px;
      position: fixed;
      top: 16px;
      right: 24px;
      z-index: 100;
      top: 32px;
      right: 32px;
      border: #e7ab42 1px solid;
      background: #e7ab42 url(../../../images/common/swim_nav_border.png) no-repeat center;
      background-size: 46px;
      border-radius: 50%;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.32);
      cursor: pointer;
      -webkit-transition: all 160ms 0ms ease-in-out;
      -moz-transition: all 160ms 0ms ease-in-out;
      -ms-transition: all 160ms 0ms ease-in-out;
      -o-transition: all 160ms 0ms ease-in-out;
      transition: all 160ms 0ms ease-in-out; } }
    @media screen and (max-width: 1220px) and (max-width: 1220px) {
      .res-recruit i.circle {
        width: 80px;
        height: 80px; } }

@media screen and (max-width: 1220px) {
      .res-recruit i.circle.cross {
        background: #e7ab42 url(../../../images/common/swim_nav_border_cross.png) no-repeat center; }
      .res-recruit i.circle:hover {
        transform: scale(1.2);
        border: rgba(255, 255, 255, 0.64) 8px solid;
        background-size: 32px; }
    .res-recruit .global-swim {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.96);
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-content: center; } }
    @media screen and (max-width: 1220px) and (min-width: 1221px) {
      .res-recruit .global-swim {
        display: none !important; } }

@media screen and (max-width: 1220px) {
      .res-recruit .global-swim ul li {
        margin: 0 0 24px;
        text-align: center; }
        .res-recruit .global-swim ul li:last-child {
          margin: 0; }
        .res-recruit .global-swim ul li a {
          color: #222;
          font-size: 32px;
          font-weight: 700;
          line-height: 1; }
          .res-recruit .global-swim ul li a:before {
            content: attr(data-eng);
            font-size: 11px;
            color: #df1214;
            display: block;
            letter-spacing: .12em;
            margin: 0 0 12px; }
          .res-recruit .global-swim ul li a:hover {
            opacity: .64; } }

.header-common {
  background: #000;
  color: #FFF;
  padding: 24px 0;
  text-align: center;
  position: relative;
  width: 100%;
  z-index: 1000; }
  .header-common.abs {
    position: absolute; }
  .header-common.yellow {
    background: rgba(231, 171, 66, 0.88); }
  .header-common.fitness {
    background: rgba(255, 255, 255, 0.2); }
    @media screen and (max-width: 768px) {
      .header-common.fitness {
        background: rgba(255, 255, 255, 0); } }
    .header-common.fitness:before {
      content: '';
      width: 100%;
      height: 4px;
      background: #df1214;
      position: absolute;
      top: 0;
      left: 0; }
      @media screen and (max-width: 768px) {
        .header-common.fitness:before {
          display: none; } }
  .header-common.red {
    background: #df1214; }
  @media screen and (max-width: 768px) {
    .header-common {
      padding: 34px 0; } }
  .header-common .logoTop {
    width: 240px;
    float: left; }
    @media screen and (max-width: 768px) {
      .header-common .logoTop {
        width: 200px;
        display: none; } }
    .header-common .logoTop img {
      width: 100%; }
  .header-common .logo {
    width: 240px;
    float: left; }
    @media screen and (max-width: 768px) {
      .header-common .logo {
        width: 200px;
        margin-left: 32px; } }
    .header-common .logo img {
      width: 100%; }
  .header-common .catch {
    float: left;
    margin: 16px 0 0 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1; }
    @media screen and (max-width: 768px) {
      .header-common .catch {
        margin: 0;
        position: relative;
        top: 38px;
        right: 200px; } }
  .header-common .gnavi {
    float: right;
    margin: 3px 0; }
    @media screen and (max-width: 1220px) {
      .header-common .gnavi {
        display: none; } }
    .header-common .gnavi.eng-sub {
      margin: 0; }
      .header-common .gnavi.eng-sub ul li a {
        font-size: 15px;
        line-height: 1; }
        .header-common .gnavi.eng-sub ul li a:after {
          content: attr(data-eng);
          font-size: 10px;
          display: block;
          text-align: center;
          margin: 8px 0 0;
          font-weight: 500; }
    .header-common .gnavi ul li {
      list-style: none;
      float: left;
      font-size: 16px;
      font-weight: 700;
      margin: 0 48px 0 0; }
      .header-common .gnavi ul li:last-child {
        margin: 0; }
      .header-common .gnavi ul li a {
        display: block;
        color: #FFF; }
        .header-common .gnavi ul li a:hover {
          opacity: .64; }

.header-swimming {
  color: #FFF;
  text-align: center;
  width: 100%;
  z-index: 1000; }
  .header-swimming.abs {
    position: absolute; }
  .header-swimming.fitness {
    background: rgba(255, 255, 255, 0.2); }
    .header-swimming.fitness:before {
      content: '';
      width: 100%;
      height: 4px;
      background: #36BAEB;
      position: absolute;
      top: 0;
      left: 0; }
  @media screen and (max-width: 768px) {
    .header-swimming {
      padding: 24px 0; } }
  .header-swimming .logo {
    position: absolute;
    left: 24px;
    top: 24px;
    width: 120px;
    float: left; }
    @media screen and (max-width: 768px) {
      .header-swimming .logo {
        width: 200px; } }
    .header-swimming .logo img {
      width: 100%; }
  .header-swimming .catch {
    float: left;
    margin: 16px 0 0 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1; }
  .header-swimming .gnavi {
    position: absolute;
    top: 36px;
    right: 140px;
    float: right;
    width: 91px;
    height: 91px;
    border-radius: 48px;
    background: url(../../images/common/responsive_nav_2x.png) no-repeat center top 13px, #36baeb;
    transition: all 150ms ease-in-out; }
    .header-swimming .gnavi.eng-sub {
      margin: 0; }
      .header-swimming .gnavi.eng-sub ul {
        display: none;
        position: absolute;
        top: 64px;
        left: 0;
        width: 100%;
        background: #FFF;
        box-shadow: 0 24px 24px rgba(0, 0, 0, 0.32); }
        .header-swimming .gnavi.eng-sub ul li {
          float: none;
          width: auto;
          margin: 0;
          border-top: #EEE 1px solid; }
          .header-swimming .gnavi.eng-sub ul li a {
            font-size: 15px;
            line-height: 1; }
            .header-swimming .gnavi.eng-sub ul li a:after {
              content: attr(data-eng);
              font-size: 10px;
              display: block;
              text-align: center;
              margin: 8px 0 0;
              font-weight: 500; }
    .header-swimming .gnavi.addClose {
      background: url(../../images/common/responsive_nav_2x.png) 0 -32px no-repeat, #36baeb; }
      .header-swimming .gnavi.addClose ul {
        position: absolute;
        top: 64px;
        background: #fff;
        display: flex !important;
        width: 1120px;
        right: 96px; }

.header-swim i.circle {
  width: 94px;
  height: 94px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
  top: 32px;
  right: 32px;
  border: #35baeb 1px solid;
  background: #35baeb url(../../../images/common/swim_nav_border.png) no-repeat center;
  background-size: 46px;
  border-radius: 50%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  -webkit-transition: all 160ms 0ms ease-in-out;
  -moz-transition: all 160ms 0ms ease-in-out;
  -ms-transition: all 160ms 0ms ease-in-out;
  -o-transition: all 160ms 0ms ease-in-out;
  transition: all 160ms 0ms ease-in-out; }
  @media screen and (max-width: 768px) {
    .header-swim i.circle {
      width: 80px;
      height: 80px; } }
  .header-swim i.circle.cross {
    background: #35baeb url(../../../images/common/swim_nav_border_cross.png) no-repeat center; }
  .header-swim i.circle:hover {
    transform: scale(1.2);
    border: rgba(255, 255, 255, 0.64) 8px solid;
    background-size: 32px; }

.header-swim .global-swim {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.96);
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center; }
  .header-swim .global-swim ul li {
    margin: 0 0 56px;
    text-align: center; }
    .header-swim .global-swim ul li:last-child {
      margin: 0; }
    .header-swim .global-swim ul li a {
      color: #222;
      font-size: 32px;
      font-weight: 700;
      line-height: 1; }
      .header-swim .global-swim ul li a:before {
        content: attr(data-eng);
        font-size: 11px;
        color: #35baeb;
        display: block;
        letter-spacing: .12em;
        margin: 0 0 12px; }
      .header-swim .global-swim ul li a:hover {
        opacity: .64; }

.footer-common {
  background: #595b61;
  padding: 64px 0;
  color: #FFF;
  text-align: center; }
  .footer-common.red {
    background: #df1214; }
  .footer-common.yellow {
    background: #e7ab42; }
  @media screen and (max-width: 768px) {
    .footer-common {
      padding: 32px 15px; } }
  .footer-common a {
    color: #FFF; }
  .footer-common .logo {
    width: 240px;
    margin: 0 auto 24px; }
    .footer-common .logo img {
      width: 100%; }
  .footer-common .nav-common {
    margin: 0 0 32px; }
    @media screen and (max-width: 768px) {
      .footer-common .nav-common {
        margin: 0 0 24px;
        border: rgba(0, 0, 0, 0.24) 1px solid;
        border-radius: 6px;
        overflow: hidden; } }
    .footer-common .nav-common ul > li {
      list-style: none;
      display: inline-block;
      margin: 0 24px;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .footer-common .nav-common ul > li {
          list-style: none;
          display: inline-block;
          margin: 16px auto;
          font-size: 15px;
          width: 40%; } }
      .footer-common .nav-common ul > li a:hover {
        opacity: .44; }
      .footer-common .nav-common ul > li i {
        font-size: 32px;
        display: inline-block;
        margin: 4px 0 0;
        color: #FFF; }
  @media screen and (max-width: 768px) {
    .footer-common.yellow .nav-common > ul, .footer-common.red .nav-common > ul {
      display: flex;
      flex-direction: column; } }

.secondview {
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center; }
  .secondview:before {
    content: '';
    background: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .32; }
    @media only screen and (max-width: 768px) {
      .secondview:before {
        opacity: .5; } }
  .secondview .display {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    height: 380px; }
    @media only screen and (max-width: 768px) {
      .secondview .display {
        height: 140px; } }
  .secondview h2 {
    color: #FFF;
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.32); }
    @media only screen and (max-width: 768px) {
      .secondview h2 {
        font-size: 28px; } }
    .secondview h2:after {
      content: attr(data-eng);
      font-size: 14px;
      margin: 16px 0 0;
      display: block;
      letter-spacing: .12em; }
      @media only screen and (max-width: 768px) {
        .secondview h2:after {
          font-size: 12px;
          margin: 8px 0 0; } }

#breadcrumb {
  padding: 14px 0;
  background: #f2f2f2; }
  @media only screen and (max-width: 768px) {
    #breadcrumb {
      padding: 15px; } }
  #breadcrumb ol {
    background: url(/images/common/home_icon_2x.png) no-repeat left 6px;
    background-size: 12px;
    padding: 0 0 0 24px; }
    @media only screen and (max-width: 768px) {
      #breadcrumb ol {
        background: url(/images/common/home_icon_2x.png) no-repeat left center;
        background-size: 12px;
        padding: 0 0 0 20px; } }
    #breadcrumb ol li {
      list-style: none;
      float: left;
      font-size: 13px;
      background: url(/images/common/arrow_2x.png) no-repeat right 8px;
      background-size: 5px;
      padding: 0 20px 0 0;
      margin: 0 16px 0 0; }
      @media only screen and (max-width: 768px) {
        #breadcrumb ol li {
          background: url(/images/common/arrow_2x.png) no-repeat right center;
          background-size: 3px;
          font-size: 10px;
          padding: 0 15px 0 0;
          margin: 0 10px 0 0; } }
      #breadcrumb ol li:last-child {
        padding: 0;
        margin: 0;
        background: none; }
      #breadcrumb ol li a {
        color: #222; }
        #breadcrumb ol li a:hover {
          text-decoration: underline; }

.header-index {
  background: #595b61;
  padding: 48px 0;
  text-align: center;
  position: relative; }
  @media screen and (max-width: 768px) {
    .header-index {
      padding: 24px 0; } }
  .header-index .logo {
    display: inline-block;
    width: 280px;
    padding: 8px 0 0; }
    @media screen and (max-width: 768px) {
      .header-index .logo {
        width: 200px; } }
    .header-index .logo img {
      width: 100%; }
  .header-index .text {
    font-size: 15px;
    line-height: 1.5;
    margin: 24px 0 0;
    color: #FFF; }
    @media screen and (max-width: 768px) {
      .header-index .text {
        margin: 16px 15px 0;
        font-size: 13px; } }

.footer-index {
  background: #595b61;
  padding: 64px 0;
  color: #FFF;
  text-align: center; }
  .footer-index.dark {
    background: #252525; }
  .footer-index.blue {
    background: #35baeb; }
  .footer-index.light-blue {
    background: #36BAEB; }
  @media screen and (max-width: 768px) {
    .footer-index {
      padding: 32px 15px; } }
  .footer-index a {
    color: #FFF; }
  .footer-index .index-nav {
    margin: 0 0 32px; }
    @media screen and (max-width: 768px) {
      .footer-index .index-nav {
        margin: 0 0 24px;
        border: rgba(0, 0, 0, 0.24) 1px solid;
        border-radius: 6px;
        overflow: hidden; } }
    .footer-index .index-nav ul > li {
      list-style: none;
      display: inline-block; }
      @media screen and (max-width: 768px) {
        .footer-index .index-nav ul > li {
          display: block;
          border-bottom: rgba(0, 0, 0, 0.24) 1px solid; }
          .footer-index .index-nav ul > li:last-child {
            border: none; }
          .footer-index .index-nav ul > li a {
            display: block;
            padding: 10px 15px;
            text-align: left;
            background: rgba(0, 0, 0, 0.12) url(../../../images/common/white_arrow_2x.png) no-repeat right 15px center;
            background-size: 5px;
            font-size: 14px; }
            .footer-index .index-nav ul > li a:hover {
              background: rgba(0, 0, 0, 0.04); } }
      .footer-index .index-nav ul > li:after {
        content: '|';
        margin: 0 20px;
        display: inline-block; }
        @media screen and (max-width: 768px) {
          .footer-index .index-nav ul > li:after {
            display: none; } }
      .footer-index .index-nav ul > li:last-child:after {
        display: none; }
  .footer-index small.copy {
    display: block; }
    @media screen and (max-width: 768px) {
      .footer-index small.copy {
        font-size: 9px; } }

.page-index {
  padding: 40px 0 8px; }
  @media screen and (max-width: 768px) {
    .page-index {
      padding: 32px 15px 8px; } }
  .page-index .flex-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    .page-index .flex-list li {
      list-style: none;
      width: 48.5%;
      margin: 0 0 32px; }
      @media screen and (max-width: 768px) {
        .page-index .flex-list li {
          width: 100%;
          margin: 0 0 24px; } }
      .page-index .flex-list li a {
        position: relative;
        color: #FFF;
        padding: 80px 0;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-size: cover; }
        @media screen and (max-width: 768px) {
          .page-index .flex-list li a {
            padding: 48px 0; } }
        .page-index .flex-list li a:hover:after {
          opacity: .12; }
        .page-index .flex-list li a.poster-1 {
          background-image: url("../../../images/index/photo_1.jpg"); }
        .page-index .flex-list li a.poster-2 {
          background-image: url("../../../images/index/photo_2.jpg"); }
        .page-index .flex-list li a.poster-3 {
          background-image: url("../../../images/index/photo_3.jpg"); }
        .page-index .flex-list li a.poster-4 {
          background-image: url("../../../images/index/photo_4.jpg"); }
        .page-index .flex-list li a dl {
          position: relative;
          z-index: 2;
          text-align: center; }
          .page-index .flex-list li a dl dt {
            font-size: 32px;
            font-weight: 700;
            line-height: 1;
            margin: 0 0 24px;
            text-shadow: 0 0 16px rgba(0, 0, 0, 0.24); }
            .page-index .flex-list li a dl dt img {
              height: 120px;
              width: auto; }
              @media screen and (max-width: 768px) {
                .page-index .flex-list li a dl dt img {
                  height: 88px; } }
        .page-index .flex-list li a:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border: rgba(0, 0, 0, 0.4) 10px solid;
          z-index: 2; }
        .page-index .flex-list li a:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background: rgba(37, 37, 37, 0.12);
          z-index: 1;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
      .page-index .flex-list li.size-l a {
        padding: 120px 0; }
        @media screen and (max-width: 768px) {
          .page-index .flex-list li.size-l a {
            padding: 64px 0; } }
      .page-index .flex-list li.col-red a:before {
        border: rgba(223, 18, 20, 0.4) 10px solid; }
      .page-index .flex-list li.col-red a:after {
        background: rgba(223, 18, 20, 0.08); }
      .page-index .flex-list li.col-blue a:before {
        border: rgba(53, 186, 235, 0.4) 10px solid; }
      .page-index .flex-list li.col-blue a:after {
        background: rgba(53, 186, 235, 0.08); }
      .page-index .flex-list li.col-yellow a:before {
        border: rgba(231, 171, 66, 0.4) 10px solid; }
      .page-index .flex-list li.col-yellow a:after {
        background: rgba(231, 171, 66, 0.08); }

.page-company .main-photo {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover; }

.page-company .context {
  font-size: 16px;
  text-align: center;
  line-height: 2.4; }

.page-company .greeting-wrapper {
  width: 80%;
  margin: auto; }

.page-company .greeting-box figure, .page-company .history-box figure {
  width: 30%;
  float: left; }
  @media screen and (max-width: 768px) {
    .page-company .greeting-box figure, .page-company .history-box figure {
      width: 100%;
      margin-bottom: 24px;
      float: none; } }
  .page-company .greeting-box figure img, .page-company .history-box figure img {
    width: 100%; }

.page-company .greeting-box .text, .page-company .history-box .text {
  float: right;
  width: 70%;
  padding-left: 48px; }
  @media screen and (max-width: 768px) {
    .page-company .greeting-box .text, .page-company .history-box .text {
      width: 100%;
      float: none;
      padding: 0; } }
  .page-company .greeting-box .text dt, .page-company .history-box .text dt {
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em;
    font-size: 15px;
    margin: 24px 0 0;
    text-align: right;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      .page-company .greeting-box .text dt, .page-company .history-box .text dt {
        margin-top: 8px; } }
    .page-company .greeting-box .text dt strong, .page-company .history-box .text dt strong {
      font-size: 28px; }
  .page-company .greeting-box .text dd, .page-company .history-box .text dd {
    font-size: 16px;
    text-align: justify; }

.page-company .history-list {
  margin: 32px 0 0; }
  .page-company .history-list li {
    list-style: none;
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: #d5d5d5 1px solid; }
    @media screen and (max-width: 768px) {
      .page-company .history-list li {
        margin: 0 0 16px;
        padding: 0 0 8px; } }
    .page-company .history-list li:last-child {
      margin: 0;
      padding: 0;
      border: none; }
    .page-company .history-list li dl {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      font-size: 15px; }
      .page-company .history-list li dl dt {
        width: 10%; }
        @media screen and (max-width: 768px) {
          .page-company .history-list li dl dt {
            width: 100%; } }
      .page-company .history-list li dl dd {
        width: 90%; }
        @media screen and (max-width: 768px) {
          .page-company .history-list li dl dd {
            width: 100%; } }

.page-company .company-tab {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .page-company .company-tab li {
    list-style: none;
    width: 49%;
    padding: 15px 0;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    background: #e5e5e5;
    border-radius: 8px 8px 0 0;
    cursor: pointer; }
    .page-company .company-tab li:hover {
      opacity: .9; }
    .page-company .company-tab li.active {
      background: #df1214;
      color: #FFF; }

.page-company .company-tab-box {
  border: #df1214 2px solid;
  padding: 32px;
  background: #FFF; }

.page-recruit #slider {
  position: relative; }
  .page-recruit #slider .catch {
    position: absolute;
    left: 50%;
    bottom: 10vh;
    transform: translateX(-50%);
    font-size: 56px;
    font-weight: 700;
    color: #FFF;
    text-shadow: 0 0 32px rgba(0, 0, 0, 0.24), 0 0 16px rgba(0, 0, 0, 0.48);
    z-index: 100; }
    @media screen and (max-width: 768px) {
      .page-recruit #slider .catch {
        font-size: calc(24px + 1vw);
        display: block;
        width: 100%;
        left: 76%; } }
  .page-recruit #slider .swiper-wrapper .swiper-slide {
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.page-recruit .interview-block .photos {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .page-recruit .interview-block .photos {
      flex-direction: column; } }
  .page-recruit .interview-block .photos li {
    width: 50%; }
    @media screen and (max-width: 768px) {
      .page-recruit .interview-block .photos li {
        width: 100%; } }
    .page-recruit .interview-block .photos li img {
      width: 100%; }

.page-recruit .interview-block .text {
  margin: 24px 10% 0; }
  @media screen and (max-width: 768px) {
    .page-recruit .interview-block .text {
      margin: 24px 0; } }

.page-recruit .job-block .list li {
  list-style: none;
  width: 32%;
  float: left;
  margin: 0 2% 2% 0;
  list-style: none;
  background: #FFF;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12); }
  .page-recruit .job-block .list li:nth-child(3n+1) {
    clear: both; }
  .page-recruit .job-block .list li:nth-child(3n+3) {
    margin-right: 0; }
  .page-recruit .job-block .list li:nth-last-child(-n+3) {
    margin-bottom: 0; }
  @media screen and (max-width: 768px) {
    .page-recruit .job-block .list li {
      width: 100%;
      margin: 0 0 24px !important; } }
  .page-recruit .job-block .list li a {
    display: block;
    color: #FFF; }
  .page-recruit .job-block .list li figure img {
    width: 100%;
    height: auto; }
  .page-recruit .job-block .list li dl {
    padding: 20px; }
    .page-recruit .job-block .list li dl dt {
      font-size: 15px;
      font-weight: 700;
      display: inline-block;
      padding: 2px 15px;
      background: #e7ab42;
      text-align: center;
      border-radius: 3px; }
    .page-recruit .job-block .list li dl dd {
      margin: 4px 0 0;
      color: #222; }
  @media screen and (max-width: 768px) {
    .page-recruit .job-block .list li:last-child {
      margin: 0 !important; } }

.page-recruit .blog-list {
  border: #f2f2f2 8px solid;
  padding: 32px;
  height: 210px;
  overflow: auto; }
  @media screen and (max-width: 768px) {
    .page-recruit .blog-list {
      padding: 20px; } }
  .page-recruit .blog-list li {
    list-style: none;
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: #d5d5d5 1px solid; }
    .page-recruit .blog-list li a {
      display: block;
      color: #222; }
      @media screen and (max-width: 768px) {
        .page-recruit .blog-list li a {
          display: flex;
          flex-direction: column;
          line-height: normal; } }
    .page-recruit .blog-list li time {
      display: inline-block;
      padding: 4px 0;
      width: 80px;
      margin: 0 15px 0 0;
      background: #ccc;
      color: #FFF;
      font-size: 12px;
      font-weight: 700;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .page-recruit .blog-list li time {
          margin: 0 0 10px; } }

.page-recruit .company-tab li.active {
  background: #e7ab42;
  color: #FFF; }

.page-recruit .company-tab-box {
  border: #e7ab42 2px solid; }

.page-fitness {
  background: rgba(0, 0, 0, 0.5); }
  .page-fitness.white {
    background: #FFF; }
  .page-fitness .fitness-fv {
    width: 100%;
    height: 100vh;
    position: relative;
    color: #FFF; }
    .page-fitness .fitness-fv:after {
      content: '';
      background: url(../../../images/common/sc_icon.png) no-repeat;
      width: 65px;
      height: 93px;
      position: absolute;
      left: 50%;
      bottom: 88px;
      transform: translateX(-50%); }
    .page-fitness .fitness-fv .logo {
      width: 400px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-60%); }
      @media screen and (max-width: 768px) {
        .page-fitness .fitness-fv .logo {
          width: 300px; } }
      .page-fitness .fitness-fv .logo img {
        width: 100%; }
    .page-fitness .fitness-fv .marquee {
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.2); }
  .page-fitness .mes-box {
    width: 660px;
    margin: 0 auto;
    background: #df1214;
    color: #FFF;
    padding: 32px 56px; }
    @media screen and (max-width: 768px) {
      .page-fitness .mes-box {
        width: 100%;
        padding: 32px; } }
    .page-fitness .mes-box dt {
      font-size: 25px;
      margin: 0 0 12px;
      text-align: center; }
  .page-fitness .photo-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    .page-fitness .photo-list li {
      width: 31%;
      list-style: none;
      background-position: center;
      background-size: cover; }
      @media screen and (max-width: 900px) {
        .page-fitness .photo-list li {
          width: 100%;
          margin-bottom: 24px; }
          .page-fitness .photo-list li:last-child {
            margin-bottom: 0; } }
      .page-fitness .photo-list li a {
        display: block;
        border: #FFF 4px solid;
        background: rgba(0, 0, 0, 0.5);
        color: #FFF;
        -webkit-transition: all 200ms 0ms ease-in-out;
        -moz-transition: all 200ms 0ms ease-in-out;
        -ms-transition: all 200ms 0ms ease-in-out;
        -o-transition: all 200ms 0ms ease-in-out;
        transition: all 200ms 0ms ease-in-out; }
        .page-fitness .photo-list li a:hover {
          border-color: #df1214; }
      .page-fitness .photo-list li dl {
        text-align: center;
        padding: 32px 0; }
        .page-fitness .photo-list li dl dt {
          font-size: 27px; }
          .page-fitness .photo-list li dl dt:after {
            content: '';
            width: 24px;
            height: 1px;
            background: #FFF;
            margin: 12px auto;
            display: block; }
  .page-fitness .bnr-text {
    background: #df1214;
    position: relative; }
    @media screen and (max-width: 768px) {
      .page-fitness .bnr-text {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        margin: auto; } }
    .page-fitness .bnr-text:before {
      content: '';
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url(../../../images/fitness/bnr_txt_bg.jpg) center;
      background-size: cover; }
      @media screen and (max-width: 768px) {
        .page-fitness .bnr-text:before {
          width: 100%;
          height: 50%; } }
    .page-fitness .bnr-text .txt {
      padding: 64px 32px;
      color: #FFF;
      width: 50%;
      margin: 0 0 0 50%; }
      @media screen and (max-width: 768px) {
        .page-fitness .bnr-text .txt {
          padding: 16px 32px;
          width: 100%;
          margin: 70% 0 0 0; } }
      .page-fitness .bnr-text .txt .btn-style {
        color: #df1214; }
  .page-fitness .traning-box {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .page-fitness .traning-box {
        flex-direction: column; } }
    .page-fitness .traning-box .harf {
      width: 48.5%;
      width: 48.5%; }
      .page-fitness .traning-box .harf ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap; }
        .page-fitness .traning-box .harf ul li {
          padding: 16px;
          background: rgba(0, 0, 0, 0.8);
          color: #fff;
          font-weight: bold;
          border-radius: 8px;
          cursor: pointer; }
          .page-fitness .traning-box .harf ul li.active {
            background: #D29F31;
            color: #111; }
      .page-fitness .traning-box .harf img {
        width: 100%; }
      @media screen and (max-width: 768px) {
        .page-fitness .traning-box .harf {
          width: 100%; } }
      .page-fitness .traning-box .harf img {
        width: 100%; }
      @media screen and (max-width: 768px) {
        .page-fitness .traning-box .harf .mb-4 {
          margin: 16px 0; } }
      .page-fitness .traning-box .harf .price-traning-con {
        border: #eee 8px solid;
        padding: 25px;
        font-size: 15px; }
        @media screen and (max-width: 768px) {
          .page-fitness .traning-box .harf .price-traning-con {
            margin: 24px 0 0; } }
        .page-fitness .traning-box .harf .price-traning-con dt {
          font-size: 20px; }
          .page-fitness .traning-box .harf .price-traning-con dt span {
            font-size: 16px;
            padding-left: 8px; }
          @media screen and (max-width: 768px) {
            .page-fitness .traning-box .harf .price-traning-con dt {
              text-align: center; } }
          .page-fitness .traning-box .harf .price-traning-con dt:after {
            content: '';
            width: 40px;
            height: 1px;
            background: #df1214;
            display: block;
            margin: 8px 0 16px; }
            @media screen and (max-width: 768px) {
              .page-fitness .traning-box .harf .price-traning-con dt:after {
                margin: 8px auto 16px; } }
        .page-fitness .traning-box .harf .price-traning-con dd {
          margin: 0 0 8px;
          font-size: 24px;
          font-weight: 700;
          display: flex;
          align-items: center; }
          @media screen and (max-width: 768px) {
            .page-fitness .traning-box .harf .price-traning-con dd {
              font-size: 18px;
              text-align: center;
              display: inherit;
              margin: 0 0 24px; } }
          .page-fitness .traning-box .harf .price-traning-con dd strong {
            font-size: 40px;
            line-height: 1; }
            @media screen and (max-width: 768px) {
              .page-fitness .traning-box .harf .price-traning-con dd strong {
                font-size: 32px; } }
          .page-fitness .traning-box .harf .price-traning-con dd:last-of-type {
            margin: 0; }
          .page-fitness .traning-box .harf .price-traning-con dd:before {
            content: attr(data-title);
            display: inline-block;
            padding: 2px 0;
            width: 120px;
            background: rgba(0, 0, 0, 0.8);
            color: #FFF;
            text-align: center;
            margin: 0 12px 0 0;
            font-weight: 700;
            font-size: 16px; }
            @media screen and (max-width: 768px) {
              .page-fitness .traning-box .harf .price-traning-con dd:before {
                display: block;
                margin: 0 auto 10px;
                font-size: 12px; } }
      .page-fitness .traning-box .harf .price-traning {
        border: #d29f31 1px solid;
        padding: 25px;
        font-size: 15px; }
        @media screen and (max-width: 768px) {
          .page-fitness .traning-box .harf .price-traning {
            margin: 24px 0 0; } }
        .page-fitness .traning-box .harf .price-traning dt {
          font-size: 20px; }
          .page-fitness .traning-box .harf .price-traning dt.gorld {
            color: #d29f31; }
            .page-fitness .traning-box .harf .price-traning dt.gorld:after {
              background: #d29f31; }
          .page-fitness .traning-box .harf .price-traning dt span {
            font-size: 16px;
            padding-left: 8px; }
            @media screen and (max-width: 768px) {
              .page-fitness .traning-box .harf .price-traning dt span {
                padding-left: 0; } }
          @media screen and (max-width: 768px) {
            .page-fitness .traning-box .harf .price-traning dt {
              text-align: center; } }
          .page-fitness .traning-box .harf .price-traning dt:after {
            content: '';
            width: 40px;
            height: 1px;
            background: #df1214;
            display: block;
            margin: 8px 0 16px; }
            @media screen and (max-width: 768px) {
              .page-fitness .traning-box .harf .price-traning dt:after {
                margin: 8px auto 16px; } }
        .page-fitness .traning-box .harf .price-traning dd {
          margin: 0 0 8px;
          font-size: 24px;
          font-weight: 700;
          display: flex;
          align-items: center; }
          @media screen and (max-width: 768px) {
            .page-fitness .traning-box .harf .price-traning dd {
              font-size: 18px;
              text-align: center;
              display: inherit;
              margin: 0 0 24px; } }
          .page-fitness .traning-box .harf .price-traning dd strong {
            font-size: 40px;
            line-height: 1; }
            @media screen and (max-width: 768px) {
              .page-fitness .traning-box .harf .price-traning dd strong {
                font-size: 32px; } }
          .page-fitness .traning-box .harf .price-traning dd:last-of-type {
            margin: 0; }
          .page-fitness .traning-box .harf .price-traning dd:before {
            content: attr(data-title);
            display: inline-block;
            padding: 2px 0;
            width: 120px;
            background: #c0c0c0;
            color: #222;
            text-align: center;
            margin: 0 12px 0 0;
            font-weight: 700;
            font-size: 16px; }
            @media screen and (max-width: 768px) {
              .page-fitness .traning-box .harf .price-traning dd:before {
                display: block;
                margin: 0 auto 10px;
                font-size: 12px; } }
  .page-fitness .traning-menu-list {
    margin: 48px 0 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .page-fitness .traning-menu-list {
        flex-direction: column; } }
    .page-fitness .traning-menu-list li {
      border: #d29f31 1px solid;
      padding: 20px;
      width: 31%; }
      @media screen and (max-width: 768px) {
        .page-fitness .traning-menu-list li:last-child {
          margin: 0; } }
      @media screen and (max-width: 768px) {
        .page-fitness .traning-menu-list li {
          width: 100%;
          margin: 0 0 24px; } }
      .page-fitness .traning-menu-list li figure img {
        width: 100%; }
      .page-fitness .traning-menu-list li dl dt {
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.5;
        margin: 24px 0 15px; }
        .page-fitness .traning-menu-list li dl dt:before {
          content: attr(data-eng);
          color: #df1214;
          display: block; }
        .page-fitness .traning-menu-list li dl dt.heading {
          font-size: 24px; }
          .page-fitness .traning-menu-list li dl dt.heading:before {
            content: attr(data-eng);
            color: #d29f31;
            display: block; }
  .page-fitness .trainer-wrap {
    margin-top: 32px;
    border: #eee 8px solid; }
    .page-fitness .trainer-wrap h3 {
      margin: 24px 0px 0px 24px;
      font-size: 24px;
      color: #111;
      font-weight: bold;
      position: relative;
      font-weight: 700; }
      .page-fitness .trainer-wrap h3::after {
        content: "";
        display: block;
        width: 48px;
        height: 1px;
        background: #df1214;
        position: absolute;
        bottom: -8px;
        left: 2px; }
  .page-fitness .training-wrap {
    border: #d29f31 1px solid !important;
    margin-top: 32px;
    padding: 24px; }
    .page-fitness .training-wrap h3 {
      font-size: 24px;
      color: #d29f31;
      padding: 0 0 8px 0;
      margin: 16px 0;
      position: relative;
      font-weight: 700; }
      .page-fitness .training-wrap h3::before {
        content: "";
        width: 48px;
        height: 1px;
        background: #d29f31;
        position: absolute;
        left: 0;
        bottom: 0; }
    .page-fitness .training-wrap .traning-traner-gorld li {
      list-style: none;
      list-style: none;
      width: 30%;
      float: left;
      margin: 0 5% 5% 0; }
      .page-fitness .training-wrap .traning-traner-gorld li:nth-child(3n+1) {
        clear: both; }
      .page-fitness .training-wrap .traning-traner-gorld li:nth-child(3n+3) {
        margin-right: 0; }
      .page-fitness .training-wrap .traning-traner-gorld li:nth-last-child(-n+3) {
        margin-bottom: 0; }
      @media screen and (max-width: 768px) {
        .page-fitness .training-wrap .traning-traner-gorld li {
          width: 100%;
          margin: 0 0 24px !important; } }
      .page-fitness .training-wrap .traning-traner-gorld li figure img {
        width: 100%; }
      .page-fitness .training-wrap .traning-traner-gorld li dl dt {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin: 16px 0 0; }
        .page-fitness .training-wrap .traning-traner-gorld li dl dt.gorld:after {
          background: #d29f31; }
        @media screen and (max-width: 768px) {
          .page-fitness .training-wrap .traning-traner-gorld li dl dt {
            margin: 12px 0 0;
            font-size: 18px;
            line-height: normal; } }
        .page-fitness .training-wrap .traning-traner-gorld li dl dt:after {
          content: '';
          width: 48px;
          height: 1px;
          background: #df1214;
          margin: 16px auto;
          display: block; }
          @media screen and (max-width: 768px) {
            .page-fitness .training-wrap .traning-traner-gorld li dl dt:after {
              margin: 10px auto; } }
      .page-fitness .training-wrap .traning-traner-gorld li dl dd {
        text-align: center; }
      @media screen and (max-width: 768px) {
        .page-fitness .training-wrap .traning-traner-gorld li:last-child {
          margin: 0 !important; } }
  .page-fitness .traning-traner-list {
    border: #EEE 8px solid;
    padding: 25px; }
    .page-fitness .traning-traner-list.border-none {
      border: none; }
    .page-fitness .traning-traner-list.gorld {
      border: #d29f31 1px solid !important; }
    .page-fitness .traning-traner-list li {
      list-style: none;
      list-style: none;
      width: 30%;
      float: left;
      margin: 0 5% 5% 0; }
      .page-fitness .traning-traner-list li:nth-child(3n+1) {
        clear: both; }
      .page-fitness .traning-traner-list li:nth-child(3n+3) {
        margin-right: 0; }
      .page-fitness .traning-traner-list li:nth-last-child(-n+3) {
        margin-bottom: 0; }
      @media screen and (max-width: 768px) {
        .page-fitness .traning-traner-list li {
          width: 100%;
          margin: 0 0 24px !important; } }
      .page-fitness .traning-traner-list li figure img {
        width: 100%; }
      .page-fitness .traning-traner-list li dl dt {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin: 16px 0 0; }
        .page-fitness .traning-traner-list li dl dt.gorld:after {
          background: #d29f31; }
        @media screen and (max-width: 768px) {
          .page-fitness .traning-traner-list li dl dt {
            margin: 12px 0 0;
            font-size: 18px;
            line-height: normal; } }
        .page-fitness .traning-traner-list li dl dt:after {
          content: '';
          width: 48px;
          height: 1px;
          background: #df1214;
          margin: 16px auto;
          display: block; }
          @media screen and (max-width: 768px) {
            .page-fitness .traning-traner-list li dl dt:after {
              margin: 10px auto; } }
      .page-fitness .traning-traner-list li dl dd {
        text-align: center; }
      @media screen and (max-width: 768px) {
        .page-fitness .traning-traner-list li:last-child {
          margin: 0 !important; } }
  .page-fitness .fitness-flex-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .page-fitness .fitness-flex-list {
        flex-direction: column; } }
    .page-fitness .fitness-flex-list.three li {
      width: 32%; }
      @media screen and (max-width: 768px) {
        .page-fitness .fitness-flex-list.three li {
          width: 100%; } }
    .page-fitness .fitness-flex-list li {
      list-style: none;
      width: 48.5%;
      background: #FFF;
      border: rgba(0, 0, 0, 0.12) 8px solid;
      padding: 25px;
      margin: 0 0 32px; }
      @media screen and (max-width: 768px) {
        .page-fitness .fitness-flex-list li {
          width: 100%; } }
      .page-fitness .fitness-flex-list li figure img {
        width: 100%; }
      .page-fitness .fitness-flex-list li dl dt {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.5;
        margin: 24px 0 15px; }
        @media screen and (max-width: 768px) {
          .page-fitness .fitness-flex-list li dl dt {
            font-size: 18px; } }
        .page-fitness .fitness-flex-list li dl dt:before {
          content: attr(data-eng);
          color: #df1214;
          display: block; }
  .page-fitness .fitness-price-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    .page-fitness .fitness-price-list li {
      width: 48.5%;
      margin: 0 0 32px;
      list-style: none;
      background: #FFF;
      padding: 25px; }
      @media screen and (max-width: 768px) {
        .page-fitness .fitness-price-list li {
          width: 100%; } }
      .page-fitness .fitness-price-list li.wide {
        width: 100%; }
      .page-fitness .fitness-price-list li.border-red {
        border: 1px solid #df1214; }
      .page-fitness .fitness-price-list li dl.price {
        font-weight: 700; }
        .page-fitness .fitness-price-list li dl.price dt {
          font-size: 20px;
          font-weight: 700;
          margin: 0 0 12px;
          padding: 0 0 12px;
          border-bottom: #d5d5d5 1px solid; }
          @media screen and (max-width: 768px) {
            .page-fitness .fitness-price-list li dl.price dt {
              text-align: center; } }
        .page-fitness .fitness-price-list li dl.price dd {
          font-size: 16px; }
          @media screen and (max-width: 768px) {
            .page-fitness .fitness-price-list li dl.price dd {
              text-align: center; } }
          .page-fitness .fitness-price-list li dl.price dd strong {
            display: inline-block;
            font-size: 40px;
            line-height: 1;
            color: #df1214;
            margin: 0 8px; }
            @media screen and (max-width: 768px) {
              .page-fitness .fitness-price-list li dl.price dd strong {
                font-size: 32px; } }
      .page-fitness .fitness-price-list li dl.desc {
        margin: 16px 0 0; }
        .page-fitness .fitness-price-list li dl.desc dt {
          background: #222;
          color: #FFF;
          padding: 4px 24px;
          display: inline-block; }
          @media screen and (max-width: 768px) {
            .page-fitness .fitness-price-list li dl.desc dt {
              width: 100%;
              text-align: center; } }
        .page-fitness .fitness-price-list li dl.desc dd {
          margin: 8px 0 0; }
  .page-fitness p.mini {
    font-size: 12px; }
  .page-fitness dl.register {
    background: #FFF;
    padding: 25px;
    margin: 0 0 24px; }
    .page-fitness dl.register dt {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 12px; }
    .page-fitness dl.register dd:before {
      content: '■';
      color: #222;
      display: inline-block;
      margin: 0 8px 0 0; }
  .page-fitness .atten li {
    margin: 0 0 16px;
    font-size: 13px; }
    .page-fitness .atten li:last-child {
      margin: 0; }
  .page-fitness .fig {
    width: 100%;
    background: #fff;
    margin: 0 0 4%;
    padding: 16px;
    border: 4px solid rgba(0, 0, 0, 0.8); }
    .page-fitness .fig img {
      width: 40%;
      margin: 0 30%; }
      @media screen and (max-width: 768px) {
        .page-fitness .fig img {
          width: 80%;
          margin: 0 10%; } }
  .page-fitness .yoga {
    padding: 40px;
    border: rgba(0, 0, 0, 0.12) 8px solid;
    margin-top: 24px; }
    @media screen and (max-width: 768px) {
      .page-fitness .yoga {
        padding: 24px; } }
    .page-fitness .yoga .yoga-wraper {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap; }
      .page-fitness .yoga .yoga-wraper li {
        width: 40%; }
        @media screen and (max-width: 768px) {
          .page-fitness .yoga .yoga-wraper li {
            width: 100%; } }
        .page-fitness .yoga .yoga-wraper li:last-child dl {
          margin-bottom: 0; }
        @media screen and (max-width: 768px) {
          .page-fitness .yoga .yoga-wraper li dl {
            margin-bottom: 24px; } }
        .page-fitness .yoga .yoga-wraper li dl dt figure img {
          width: 100%; }
        .page-fitness .yoga .yoga-wraper li dl dd.time {
          text-align: center;
          font-size: 34px;
          font-weight: bold; }

.page-calture {
  padding: 80px 0; }
  @media screen and (max-width: 768px) {
    .page-calture {
      padding: 40px 0; } }
  .page-calture .border-wrap {
    border: 3px solid #eee;
    border-top: none;
    padding: 16px; }
  .page-calture .teacher dt {
    width: 90%; }
  .page-calture .teacher dd {
    font-size: 14px;
    width: 90%; }
    @media screen and (max-width: 768px) {
      .page-calture .teacher dd {
        width: 100%; } }
    .page-calture .teacher dd.box {
      position: relative;
      margin: 0 0 16px 96px !important; }
      @media screen and (max-width: 768px) {
        .page-calture .teacher dd.box {
          margin: 40px 0 0 0 !important; } }
      .page-calture .teacher dd.box:before {
        display: block !important;
        content: attr(data-title);
        background: #35baeb;
        text-align: center;
        width: 100px;
        color: #FFF;
        padding: 2px 0;
        font-weight: 700;
        position: absolute;
        top: -1;
        left: -96px;
        font-size: 13px;
        border-radius: 4px; }
        @media screen and (max-width: 768px) {
          .page-calture .teacher dd.box:before {
            top: -32px;
            left: 0; } }
    .page-calture .teacher dd:before {
      display: none !important; }
  .page-calture .resson-photo {
    padding: 0 0 48px;
    cursor: pointer;
    padding: 16px;
    border: 3px solid #eee;
    position: relative; }
    .page-calture .resson-photo.arrowUp::before {
      content: "";
      display: inline-block;
      width: 80px;
      height: 80px;
      background: url(../../../images/culture/arrowup.png) no-repeat right 15px center;
      position: absolute;
      top: 40%;
      right: 5%; }
      @media screen and (max-width: 768px) {
        .page-calture .resson-photo.arrowUp::before {
          width: 98px;
          margin: auto;
          top: auto;
          bottom: -6px;
          left: 5%; } }
    .page-calture .resson-photo.arrowDown::before {
      content: "";
      display: inline-block;
      width: 80px;
      height: 80px;
      background: url(../../../images/culture/arrowdown.png) no-repeat right 15px center;
      border-bottom: none;
      position: absolute;
      top: 40%;
      right: 5%; }
      @media screen and (max-width: 768px) {
        .page-calture .resson-photo.arrowDown::before {
          width: 98px;
          margin: auto;
          top: auto;
          bottom: -6px;
          left: 5%; } }
    .page-calture .resson-photo figure {
      width: 30%;
      float: left; }
      @media screen and (max-width: 768px) {
        .page-calture .resson-photo figure {
          width: 100%;
          float: none;
          margin-bottom: 8px; } }
      .page-calture .resson-photo figure img {
        width: 100%; }
    .page-calture .resson-photo dl {
      width: 67%;
      float: right; }
      @media screen and (max-width: 768px) {
        .page-calture .resson-photo dl {
          width: 100%;
          float: none; } }
      .page-calture .resson-photo dl dt {
        font-size: 32px;
        font-weight: 700;
        border-bottom: #d5d5d5 1px solid;
        margin: 0 0 16px;
        padding: 0 0 4px; }
        @media screen and (max-width: 768px) {
          .page-calture .resson-photo dl dt {
            font-size: 22px; } }
      .page-calture .resson-photo dl dd {
        position: relative;
        padding: 0 0 0 120px;
        margin: 0 0 12px;
        font-size: 15px; }
        @media screen and (max-width: 768px) {
          .page-calture .resson-photo dl dd {
            padding: 32px 0 0 0; } }
        .page-calture .resson-photo dl dd:last-of-type {
          margin: 0;
          padding-bottom: 40px; }
        .page-calture .resson-photo dl dd:before {
          content: attr(data-title);
          background: #35baeb;
          text-align: center;
          width: 100px;
          color: #FFF;
          padding: 2px 0;
          font-weight: 700;
          position: absolute;
          top: 2px;
          left: 0;
          font-size: 13px;
          border-radius: 4px; }
  .page-calture .resson-description .text {
    font-size: 15px; }
  .page-calture .resson-description .list {
    margin: 20px 0 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    .page-calture .resson-description .list li {
      width: 31.5%;
      background: #FFF;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08); }
      @media screen and (max-width: 768px) {
        .page-calture .resson-description .list li {
          width: 100%;
          margin-bottom: 24px; } }
      .page-calture .resson-description .list li dl dt {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        background: #35baeb;
        color: #FFF;
        padding: 8px 0; }
      .page-calture .resson-description .list li dl dd {
        padding: 15px 32px;
        font-size: 16px; }
  .page-calture .teacher-box .contact {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 8px; }
  .page-calture .teacher-box .teacher {
    margin-bottom: 32px; }
    .page-calture .teacher-box .teacher figure {
      width: 30%;
      float: left; }
      @media screen and (max-width: 768px) {
        .page-calture .teacher-box .teacher figure {
          width: 100%;
          margin-bottom: 24px;
          float: none; } }
      .page-calture .teacher-box .teacher figure img {
        width: 100%; }
    .page-calture .teacher-box .teacher dl {
      width: 70%;
      padding: 0 0 0 40px;
      float: right; }
      @media screen and (max-width: 768px) {
        .page-calture .teacher-box .teacher dl {
          width: 100%;
          padding: 0; } }
      .page-calture .teacher-box .teacher dl dt {
        font-size: 22px;
        font-weight: 700;
        margin: 0 0 12px;
        padding: 0 0 10px;
        border-bottom: #d5d5d5 1px solid;
        width: 85%; }
      .page-calture .teacher-box .teacher dl dd {
        margin: 0 0 8px; }
        .page-calture .teacher-box .teacher dl dd:before {
          content: attr(data-year);
          width: 80px;
          padding: 4px 0;
          background: #35baeb;
          color: #FFF;
          display: inline-block;
          text-align: center;
          margin: 0 16px 0 0; }

.fitness-price-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .fitness-price-list li {
    width: 48.5%;
    margin: 0 0 32px;
    list-style: none;
    background: #FFF;
    padding: 25px; }
    @media screen and (max-width: 768px) {
      .fitness-price-list li {
        width: 100%; } }
    .fitness-price-list li.wide {
      width: 100%; }
    .fitness-price-list li.border-red {
      border: 1px solid #df1214; }
    .fitness-price-list li dl.price {
      font-weight: 700; }
      .fitness-price-list li dl.price dt {
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 12px;
        padding: 0 0 12px;
        border-bottom: #d5d5d5 1px solid; }
        @media screen and (max-width: 768px) {
          .fitness-price-list li dl.price dt {
            text-align: center; } }
      .fitness-price-list li dl.price dd {
        font-size: 16px; }
        @media screen and (max-width: 768px) {
          .fitness-price-list li dl.price dd {
            text-align: center; } }
        .fitness-price-list li dl.price dd strong {
          display: inline-block;
          font-size: 40px;
          line-height: 1;
          color: #df1214;
          margin: 0 8px; }
          @media screen and (max-width: 768px) {
            .fitness-price-list li dl.price dd strong {
              font-size: 32px; } }
    .fitness-price-list li dl.desc {
      margin: 16px 0 0; }
      .fitness-price-list li dl.desc dt {
        background: #222;
        color: #FFF;
        padding: 4px 24px;
        display: inline-block; }
        @media screen and (max-width: 768px) {
          .fitness-price-list li dl.desc dt {
            width: 100%;
            text-align: center; } }
      .fitness-price-list li dl.desc dd {
        margin: 8px 0 0; }

p.mini {
  font-size: 12px; }

dl.register {
  background: #FFF;
  padding: 25px;
  margin: 0 0 24px; }
  dl.register dt {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 12px; }
  dl.register dd:before {
    content: '■';
    color: #222;
    display: inline-block;
    margin: 0 8px 0 0; }

.page-class .table-style tbody tr th, .page-class .table-style tbody tr td {
  font-size: 15px;
  border: rgba(51, 185, 235, 0.64) 1px solid; }

.page-class .table-style tbody tr th {
  background: rgba(53, 186, 235, 0.72);
  color: #FFF; }

.page-class .table-style tbody tr.color1 td {
  background: rgba(53, 186, 235, 0.12); }

.page-class .table-style tbody tr.color2 td {
  background: rgba(53, 186, 235, 0.16); }

.page-class .table-style.stripe tbody tr:nth-child(2n+2) td {
  background: rgba(53, 186, 235, 0.08); }

.page-interview .interview-box {
  border: #eee 8px solid;
  padding: 32px;
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .page-interview .interview-box {
      padding: 20px;
      border: #eee 4px solid; } }
  .page-interview .interview-box:last-of-type {
    margin: 0; }
  .page-interview .interview-box dl.names {
    position: relative;
    border-bottom: #d5d5d5 1px solid;
    padding: 0 0 24px;
    margin: 0 0 24px; }
    .page-interview .interview-box dl.names dt {
      font-size: 28px;
      line-height: 1;
      padding-right: 30%; }
      @media screen and (max-width: 768px) {
        .page-interview .interview-box dl.names dt {
          padding: 0;
          line-height: normal;
          font-size: 18px; } }
    .page-interview .interview-box dl.names dd {
      font-size: 28px;
      font-weight: 700;
      line-height: 1;
      position: absolute;
      top: 0;
      right: 0; }
      @media screen and (max-width: 768px) {
        .page-interview .interview-box dl.names dd {
          position: static;
          display: flex;
          flex-direction: column;
          text-align: center;
          font-size: 16px; } }
      .page-interview .interview-box dl.names dd:before {
        content: attr(data-job);
        background: #df1214;
        padding: 8px 16px;
        display: inline-block;
        color: #FFF;
        font-size: 13px;
        vertical-align: middle;
        margin: 0 20px 0 0;
        transform: translateY(-2px); }
        @media screen and (max-width: 768px) {
          .page-interview .interview-box dl.names dd:before {
            margin: 8px 0; } }
  .page-interview .interview-box .photo {
    width: 30%;
    float: left; }
    @media screen and (max-width: 768px) {
      .page-interview .interview-box .photo {
        width: 100%; } }
    .page-interview .interview-box .photo img {
      width: 100%; }
  .page-interview .interview-box .text {
    width: 70%;
    float: right;
    padding-left: 40px;
    font-size: 16px; }
    @media screen and (max-width: 768px) {
      .page-interview .interview-box .text {
        width: 100%;
        padding: 0;
        margin: 16px 0 0;
        font-size: 14px; } }

.page-swim section {
  background: #FFF; }

.page-swim .blue-bg {
  background: #35baeb;
  color: #FFF; }

.page-swim .sky-bg {
  background: #f3f9fb; }

.page-swim .swim-contact-bg {
  background: url(../../../images/swim/swim-contact-bg.jpg);
  background-size: cover;
  position: relative; }
  .page-swim .swim-contact-bg:before {
    content: '';
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .88; }

.page-swim .wave-text {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: #FFF; }
  .page-swim .wave-text:after {
    display: block;
    content: '';
    background: url(../../../images/swim/h_wave.png);
    width: 64px;
    height: 13px;
    margin: 12px auto 24px; }
  .page-swim .wave-text.blue {
    color: #35baeb; }
    .page-swim .wave-text.blue:after {
      background: url(../../../images/swim/h_wave_b.png); }

.page-swim .swim-fv {
  width: 100%;
  height: 100vh;
  position: relative;
  color: #FFF;
  background: none !important; }
  .page-swim .swim-fv:after {
    content: '';
    background: url(../../../images/swim/wave.png) left top repeat-x;
    width: 100%;
    height: 88px;
    position: absolute;
    left: 0;
    bottom: 40px;
    animation: anime1 8s linear 0s infinite; }

@keyframes anime1 {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 1400px 0; } }
  .page-swim .swim-fv .logo {
    width: 280px;
    position: absolute;
    bottom: 200px;
    left: 50%;
    transform: translateX(-50%); }
    .page-swim .swim-fv .logo img {
      width: 100%; }
  .page-swim .swim-fv .marquee {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #aee3f7;
    padding: 0;
    height: 40px;
    line-height: 40px;
    color: #222; }

.page-swim .listable {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  padding: 0 180px; }
  @media screen and (max-width: 769px) {
    .page-swim .listable {
      padding: 0; } }
  .page-swim .listable li {
    list-style: none;
    width: 48%;
    margin: 0 0 40px; }
    @media screen and (max-width: 769px) {
      .page-swim .listable li {
        width: 100%;
        margin: 0 0 32px; } }
    .page-swim .listable li figure img {
      width: 100%; }
    .page-swim .listable li dl {
      background: #35baeb;
      color: #FFF;
      text-align: center;
      padding: 15px 0;
      font-weight: 700; }
      .page-swim .listable li dl dt {
        font-size: 24px; }
        .page-swim .listable li dl dt:after {
          content: '';
          width: 48px;
          height: 1px;
          background: #FFF;
          margin: 8px auto 8px;
          display: block; }
      .page-swim .listable li dl dd {
        font-size: 18px; }
        .page-swim .listable li dl dd.btn-area {
          margin: 12px 0 0;
          text-align: center; }
          .page-swim .listable li dl dd.btn-area a {
            color: #35baeb; }

.page-swim .taiken {
  text-align: center; }
  .page-swim .taiken dt {
    font-size: 24px;
    font-weight: 700;
    color: #35baeb;
    margin: 0 0 16px; }

.page-swim .taiken-culture {
  text-align: center;
  margin-top: 24px; }

.page-swim .kussion {
  padding: 180px 0;
  background: url(../../../images/swim/swim_kussion.jpg) no-repeat center bottom;
  background-size: cover;
  color: #FFF; }

.page-swim .blueborder {
  border: #35baeb 4px solid; }

.page-swim .fig {
  width: 100%;
  background: #fff;
  margin: 0 0 4%;
  padding: 16px;
  box-shadow: 8px 8px 14px 0px rgba(0, 0, 0, 0.09); }
  .page-swim .fig img {
    width: 40%;
    margin: 0 30%; }
    @media screen and (max-width: 768px) {
      .page-swim .fig img {
        width: 80%;
        margin: 0 10%; } }

.motion-animate {
  opacity: 0;
  transform: translateY(-40px);
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .motion-animate.active {
    transform: translateY(0);
    opacity: 1; }

.dark {
  background: rgba(0, 0, 0, 0.8);
  color: #fff; }

.MODULE.bg {
  background: #f2f2f2; }

.MODULE .btn-style {
  text-align: center;
  -webkit-transition: all 200ms 0ms ease-in-out;
  -moz-transition: all 200ms 0ms ease-in-out;
  -ms-transition: all 200ms 0ms ease-in-out;
  -o-transition: all 200ms 0ms ease-in-out;
  transition: all 200ms 0ms ease-in-out; }
  @media screen and (max-width: 768px) {
    .MODULE .btn-style {
      width: 80%; } }
  .MODULE .btn-style:hover {
    opacity: .88; }
  .MODULE .btn-style.input {
    border: none;
    font-size: 18px;
    font-weight: 700;
    padding: 8px 64px !important;
    cursor: pointer; }
    @media screen and (max-width: 768px) {
      .MODULE .btn-style.input {
        font-size: 16px;
        padding: 8px 16px !important; } }
  .MODULE .btn-style.red {
    background: #df1214;
    color: #FFF; }
  .MODULE .btn-style.white {
    background: #FFF;
    color: #222; }
  .MODULE .btn-style.blue {
    background: #35baeb;
    color: #FFF; }
  .MODULE .btn-style.yellow {
    background: #e7ab42;
    color: #FFF; }
  .MODULE .btn-style.style-ghost {
    display: inline-block;
    border: #FFF 2px solid;
    padding: 4px 24px;
    color: #FFF; }
  .MODULE .btn-style.style-radius {
    display: inline-block;
    padding: 4px 24px;
    border-radius: 8px; }
  .MODULE .btn-style.style-circle {
    display: inline-block;
    padding: 4px 24px;
    border-radius: 999px; }
  .MODULE .btn-style.style-square {
    display: inline-block;
    padding: 4px 24px; }
  .MODULE .btn-style i {
    display: inline-block;
    margin: 0 0 0 12px; }

.MODULE.wapper {
  padding: 20px 0; }

.MODULE.wrap {
  padding: 80px 0; }
  @media screen and (max-width: 769px) {
    .MODULE.wrap {
      padding: 40px 0; } }
  .MODULE.wrap.no-top {
    padding-top: 0; }
  .MODULE.wrap.no-bottom {
    padding-bottom: 0; }

.MODULE h2.bottom-border {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .MODULE h2.bottom-border {
      font-size: 24px; } }
  .MODULE h2.bottom-border.red:after {
    background: #df1214; }
  .MODULE h2.bottom-border.yellow:after {
    background: #e7ab42; }
  .MODULE h2.bottom-border:after {
    content: '';
    width: 56px;
    height: 4px;
    background: #000;
    margin: 12px auto 0;
    display: block; }

.MODULE h2.center-border {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  position: relative;
  margin: 0 0 32px; }
  .MODULE h2.center-border.black {
    color: #222; }
  .MODULE h2.center-border.red:after {
    background: #df1214; }
  .MODULE h2.center-border.gorld {
    color: #fff; }
    .MODULE h2.center-border.gorld::after {
      background: #d29f31; }
  .MODULE h2.center-border:before {
    content: attr(data-eng);
    font-size: 34px;
    display: block;
    margin: 0 0 20px; }
  .MODULE h2.center-border:after {
    content: '';
    width: 144px;
    height: 1px;
    background: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(8px); }
  @media screen and (max-width: 768px) {
    .MODULE h2.center-border.two_lines:after {
      top: 64%; } }

@media only screen and (max-width: 768px) {
  .MODULE h2.bar-magin:after {
    top: 64%; } }

.MODULE h3.borders-gorld {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  padding: 0 0 8px 16px;
  margin: 0 0 20px;
  position: relative;
  top: 0;
  left: 0;
  border-bottom: 4px solid;
  border-image: linear-gradient(135deg, #a79756, #faf8c5, #a79756);
  border-image-slice: 1; }
  .MODULE h3.borders-gorld:before {
    content: '';
    width: 4px;
    height: 80%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0; }

.MODULE h3.borders {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  padding: 0 0 8px 16px;
  margin: 0 0 20px;
  position: relative;
  top: 0;
  left: 0;
  border-bottom: rgba(0, 0, 0, 0.08) 1px solid; }
  .MODULE h3.borders.bold {
    border-bottom: #222 2px solid; }
  .MODULE h3.borders.black {
    color: #222; }
    .MODULE h3.borders.black:before {
      background: #222; }
  .MODULE h3.borders.white {
    color: #fff;
    border-bottom: #d29f31 2px solid; }
    .MODULE h3.borders.white:before {
      background: #d29f31; }
  .MODULE h3.borders.yellow:before {
    background: #e7ab42; }
  .MODULE h3.borders.blue:before {
    background: #35baeb; }
  .MODULE h3.borders.light-blue:before {
    background: #36BAEB; }
  @media only screen and (max-width: 768px) {
    .MODULE h3.borders {
      font-size: 17px;
      margin: 0 0 12px; } }
  .MODULE h3.borders:before {
    content: '';
    width: 4px;
    height: 80%;
    background: #df1214;
    position: absolute;
    top: 0;
    left: 0; }

.MODULE .table-style {
  border: #d5d5d5 1px solid;
  width: 100%;
  border-collapse: collapse; }
  @media screen and (max-width: 768px) {
    .MODULE .table-style {
      width: 100%;
      margin: auto;
      display: block;
      overflow-x: scroll;
      white-space: nowrap; } }
  .MODULE .table-style tr th, .MODULE .table-style tr td {
    padding: 15px;
    text-align: left;
    border: #d5d5d5 1px solid;
    background: #FFF;
    vertical-align: middle; }
  .MODULE .table-style tr th {
    width: 20%;
    background: #f2f2f2; }
    .MODULE .table-style tr th.hiss:after {
      content: '※';
      color: #b90f11;
      font-weight: 700;
      display: inline-block;
      margin: 0 0 0 12px; }
  @media screen and (max-width: 768px) {
    .MODULE .table-style.contact {
      display: table; }
      .MODULE .table-style.contact tr {
        display: flex;
        flex-direction: column; }
        .MODULE .table-style.contact tr th,
        .MODULE .table-style.contact tr td {
          width: 100%; } }

.MODULE .items-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .MODULE .items-list li {
    width: 48%;
    margin: 0 0 4%;
    padding: 148px 0 0;
    background-color: #EEE;
    background-size: cover; }
    @media screen and (max-width: 768px) {
      .MODULE .items-list li {
        width: 100%;
        margin: 0 0 32px; }
        .MODULE .items-list li:last-child {
          margin: 0; } }
    .MODULE .items-list li dl {
      background: rgba(0, 0, 0, 0.75);
      padding: 15px;
      color: #FFF; }
      .MODULE .items-list li dl dt {
        font-size: 20px;
        font-weight: 700; }
      .MODULE .items-list li dl dd {
        font-size: 12px; }

.MODULE .gmap iframe {
  width: 100%;
  height: 400px;
  vertical-align: bottom; }
  @media screen and (max-width: 768px) {
    .MODULE .gmap iframe {
      height: 560px; } }

.MODULE .acc-data figure {
  width: 50%;
  float: left;
  padding-right: 56px; }
  @media screen and (max-width: 768px) {
    .MODULE .acc-data figure {
      width: 100%;
      padding: 0;
      margin-bottom: 24px;
      float: none; } }
  .MODULE .acc-data figure img {
    width: 100%; }

.MODULE .acc-data .shopdata {
  width: 50%;
  float: right;
  color: #FFF; }
  @media screen and (max-width: 768px) {
    .MODULE .acc-data .shopdata {
      width: 100%;
      padding: 0;
      float: none; } }
  .MODULE .acc-data .shopdata.swim {
    color: #222; }
    .MODULE .acc-data .shopdata.swim dl dt {
      border-color: #d5d5d5; }
      .MODULE .acc-data .shopdata.swim dl dt:after {
        color: #35baeb; }
    .MODULE .acc-data .shopdata.swim dl dd.dec:before {
      background: #EEE;
      color: #222; }
  .MODULE .acc-data .shopdata.none-shadow dl {
    text-shadow: none; }
    .MODULE .acc-data .shopdata.none-shadow dl dd.dec:before {
      box-shadow: none; }
  .MODULE .acc-data .shopdata dl {
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.56);
    margin: 0 0 24px; }
    .MODULE .acc-data .shopdata dl:last-of-type {
      margin: 0; }
    .MODULE .acc-data .shopdata dl dt {
      font-size: 24px;
      font-weight: 700;
      margin: 0 0 12px;
      padding: 0 0 8px;
      border-bottom: #FFF 2px solid; }
      .MODULE .acc-data .shopdata dl dt:after {
        content: attr(data-eng);
        font-size: 12px;
        color: #df1214;
        display: inline-block;
        margin: 0 0 0 24px; }
    .MODULE .acc-data .shopdata dl dd {
      margin: 0 0 12px; }
      .MODULE .acc-data .shopdata dl dd.dec {
        font-size: 16px;
        font-weight: 700; }
        .MODULE .acc-data .shopdata dl dd.dec:before {
          content: attr(data-text);
          background: #999;
          padding: 2px 0;
          width: 140px;
          display: inline-block;
          margin: 0 16px 0 0;
          text-align: center;
          font-size: 18px;
          text-shadow: none;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.56); }
          @media screen and (max-width: 768px) {
            .MODULE .acc-data .shopdata dl dd.dec:before {
              display: block;
              margin-bottom: 8px;
              width: auto;
              margin: 0; } }
      .MODULE .acc-data .shopdata dl dd:last-of-type {
        margin: 0; }

.MODULE ul.contacts {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .MODULE ul.contacts li {
    width: 48%;
    list-style: none;
    background: #FFF; }
    @media screen and (max-width: 768px) {
      .MODULE ul.contacts li {
        width: 100%;
        margin-bottom: 24px; }
        .MODULE ul.contacts li:last-child {
          margin-bottom: 0; } }
    .MODULE ul.contacts li.tel dl dt {
      background: #5fdf12; }
    .MODULE ul.contacts li.tel dl dd {
      font-weight: 700;
      font-size: 25px; }
      .MODULE ul.contacts li.tel dl dd a {
        color: #222;
        display: block;
        margin: 8px 0 0; }
        @media screen and (max-width: 768px) {
          .MODULE ul.contacts li.tel dl dd a {
            margin: 0; } }
      .MODULE ul.contacts li.tel dl dd strong {
        font-size: 38px;
        display: inline-block;
        margin: 0 0 0 16px;
        line-height: 1; }
        @media screen and (max-width: 768px) {
          .MODULE ul.contacts li.tel dl dd strong {
            font-size: 34px;
            margin: 0; } }
    .MODULE ul.contacts li.contact dl dt {
      background: #df1214; }
    .MODULE ul.contacts li.contact dl dd a {
      color: #FFF;
      background: #df1214 url(../../images/common/white_arrow_2x.png) no-repeat right 15px center;
      background-size: 8px;
      display: block;
      font-size: 16px;
      font-weight: 700;
      padding: 15px 0; }
    .MODULE ul.contacts li dl dt {
      font-size: 16px;
      background: #EEE;
      color: #FFF;
      text-align: center;
      padding: 4px 0; }
    .MODULE ul.contacts li dl dd {
      text-align: center;
      padding: 8px; }

.MODULE .none {
  display: none; }
  @media screen and (max-width: 768px) {
    .MODULE .none {
      display: block; } }

.marquee {
  width: 100%;
  padding: 15px 0;
  overflow: hidden; }
  .marquee p {
    padding-left: 100%;
    display: inline-block;
    white-space: nowrap;
    animation-name: marquee;
    animation-timing-function: linear;
    animation-duration: 10s;
    animation-iteration-count: infinite; }

@keyframes marquee {
  from {
    transform: translate(0%); }
  99%, to {
    transform: translate(-100%); } }

video#fixed {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  width: auto;
  min-height: 100%;
  height: auto;
  z-index: -1; }

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }

.fade-enter, .fade-leave-to {
  opacity: 0; }

.zoom-enter-active, .zoom-leave-active {
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  opacity: 1;
  transform: scale(1); }

.zoom-enter, .zoom-leave-to {
  opacity: 0;
  transform: scale(1.2); }

.center {
  text-align: center;
  margin-top: 24px; }

.calendar {
  margin-top: 32px;
  text-align: center; }
  .calendar h4 {
    font-size: 24px;
    margin-bottom: 16px;
    text-align: center;
    position: relative; }
    .calendar h4::before {
      content: "";
      width: 96px;
      height: 2px;
      background: #35baeb;
      position: absolute;
      bottom: -4px; }
  @media screen and (max-width: 768px) {
    .calendar iframe {
      width: 100%; } }

.head {
  font-size: 22px;
  margin-bottom: 24px;
  padding-bottom: 8px;
  font-weight: bold;
  border-bottom: solid 3px #35baeb;
  display: inline-block; }
