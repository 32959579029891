.page-company{
	.main-photo{
		width: 100%;
		height: 100vh;
		background-position: center;
		background-size: cover;
	}
	.context{
		font-size: 16px;
		text-align: center;
		line-height: 2.4;
	}
	.greeting-wrapper{
        width: 80%;
        margin: auto;		
	}
	.greeting-box{
		figure{
			width: 30%;
			float: left;
            @media screen and (max-width:768px){
                width: 100%;
                margin-bottom: 24px;
				float: none;
            } 
			img{
				width: 100%;
			}
		}
		.text{
			float: right;
			width: 70%;
			padding-left: 48px;
            @media screen and (max-width:768px){
                width: 100%;
				float: none;
				padding: 0;
            }
			dt{
				@include min(); 
				font-size: 15px;
				margin: 24px 0 0;
				text-align: right;
				font-weight: 700;
                @media screen and (max-width:768px){
                    margin-top: 8px;
                }
				strong{
					font-size: 28px;
				}
			}
			dd{
				font-size: 16px;
				text-align: justify;
			}
		}
	}
	.history-box{
		@extend .greeting-box;
	}
	.history-list{
		margin: 32px 0 0;
		li{
			list-style: none;
			margin: 0 0 20px;
			padding: 0 0 20px;
			border-bottom: $border 1px solid;
            @media screen and (max-width:768px){
                margin: 0 0 16px;
                padding: 0 0 8px; 
            }
			&:last-child{
				margin: 0;
				padding: 0;
				border: none;
			}
			dl{
				@include flex();
				font-size: 15px;
				dt{
					width: 10%;
                    @media screen and (max-width:768px){
                    	width: 100%;
                    }
				}
				dd{
					width: 90%;
                    @media screen and (max-width:768px){
                    	width: 100%;
                    }
				}
			}
		}
	}
	.company-tab{
		@include flex();
		li{
			list-style: none;
			width: 49%;
			padding: 15px 0;
			text-align: center;
			font-size: 18px;
			font-weight: 700;
			background: #e5e5e5;
			border-radius: 8px 8px 0 0;
			cursor: pointer;
			&:hover{
				opacity: .9;
			}
			&.active{
				background: $red;
				color: #FFF;
			}
		}
	}
	.company-tab-box{
		border: $red 2px solid;
		padding: 32px;
		background: #FFF;
	}
}
