.page-recruit{
	#slider{
		position: relative;
		.catch{
			position: absolute;
			left: 50%;
			bottom: 10vh;
			transform: translateX(-50%);
			font-size: 56px;
			font-weight: 700;
			color: #FFF;
			text-shadow: 0 0 32px rgba(#000,.24) , 0 0 16px rgba(#000,.48);
			z-index: 100;
            @media screen and (max-width:768px){
                font-size: calc(24px + 1vw);
                display: block;
                width: 100%;
                left: 76%; 
            }
		}
		.swiper-wrapper{
			.swiper-slide{
				width: 100%;
				height: 100vh;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}
	.interview-block{
		.photos{
			@include flex();
            @media screen and (max-width:768px){
                flex-direction: column;
            }
			li{
				width: 50%;
                @media screen and (max-width:768px){
                    width: 100%;
                }
				img{
					width: 100%;
				}
			}
		}
		.text{
			margin: 24px 10% 0;
            @media screen and (max-width:768px){
                margin: 24px 0;
            }
		}
	}
	.job-block{
		.list{
			li{
				@include clum(3,32%,2%);
				list-style: none;
				background: #FFF;
				box-shadow: 0 4px 16px rgba(#000,.12);
                @media screen and (max-width:768px){
                    width: 100%;
                    margin: 0 0 24px!important;
                }
				a{
					display: block;
					color: #FFF;
				}
				figure{
					img{
						width: 100%;
						height: auto;
					}
				}
				dl{
					padding: 20px;
					dt{
						font-size: 15px;
						font-weight: 700;
						display: inline-block;
						padding: 2px 15px;
						background: $yellow;
						text-align: center;
						border-radius: 3px;
					}
					dd{
						margin: 4px 0 0;
						color: $normal;
					}
				}
                &:last-child{
                    @media screen and (max-width:768px){
                        margin: 0!important;
                    }
                }
			}
		}
	}
	.blog-list{
		border: #f2f2f2 8px solid;
		padding: 32px;
		height: 210px;
		overflow: auto;
        @media screen and (max-width:768px){
            padding: 20px;
        }
		li{
			list-style: none;
			margin: 0 0 16px;
			padding: 0 0 16px;
			border-bottom: $border 1px solid;
			a{
				display: block;
				color: $normal;
                @media screen and (max-width:768px){
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                }
			}
			time{
				display: inline-block;
				padding: 4px 0;
				width: 80px;
				margin: 0 15px 0 0;
				background: #ccc;
				color: #FFF;
				font-size: 12px;
				font-weight: 700;
				text-align: center;
                @media screen and (max-width:768px){
                    margin: 0 0 10px;
                }
			}
		}
	}
	.company-tab{
		li{
			&.active{
				background: $yellow;
				color: #FFF;
			}
		}
	}
	.company-tab-box{
		border: $yellow 2px solid;
	}
}
