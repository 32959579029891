.page-calture{
    padding: 80px 0;
    @media screen and (max-width:768px){
        padding: 40px 0;
    }
	.border-wrap{
	border: 3px solid #eee;
	border-top: none;
	padding: 16px;
	}
    .teacher{
		dt{
			width: 90%;
		}
        dd{
			font-size: 14px;
			width: 90%;
            @media screen and (max-width:768px){
				width: 100%;
            }
		&.box{ 
			position: relative;
            margin: 0 0 16px 96px!important; 
            @media screen and (max-width:768px){
				margin: 40px 0 0 0!important; 
            }
            &:before{ 
				display: block!important; 
                content: attr(data-title); 
                background: $blue;
                text-align: center;
                width: 100px; 
                color: #FFF;
                padding: 2px 0;
                font-weight: 700;   
                position: absolute;
                top: -1;
                left: -96px;
                font-size: 13px;
                border-radius: 4px;
                @media screen and (max-width:768px){
                    top: -32px;
					left: 0;
                }
            }
		}
            &:before{
                display: none !important;
            }
        }
    }
	.resson-photo{
		padding: 0 0 48px;
		cursor: pointer; 
		padding: 16px;
        border: 3px solid #eee;
		position: relative;
		&.arrowUp{
            &::before{
                content: "";
                display: inline-block;
                width: 80px;
                height: 80px;
                background: url(../../../images/culture/arrowup.png) no-repeat right 15px center;
                position: absolute;
                top: 40%;
                right: 5%;
                @media screen and (max-width:768px){
                    width: 98px;
                    margin: auto;
                    top: auto;
                    bottom: -6px;
                    left: 5%;
                }
			}
		}
		&.arrowDown{
            &::before{
                content: "";
                display: inline-block;
                width: 80px;
                height: 80px;
				background: url(../../../images/culture/arrowdown.png) no-repeat right 15px center;
				border-bottom: none;
                position: absolute;
                top: 40%;
                right: 5%;
                @media screen and (max-width:768px){
                    width: 98px;
                    margin: auto;
                    top: auto;
                    bottom: -6px;
                    left: 5%;
                }
			} 
		}
		figure{
			width: 30%;
			float: left;
            @media screen and (max-width:768px){
                width: 100%;
				float: none;
				margin-bottom: 8px;
            }
			img{
				width: 100%;
			}
		}
		dl{
			width: 67%;
			float: right;
            @media screen and (max-width:768px){
                width: 100%;
				float: none;
            } 
			dt{
				font-size: 32px;
				font-weight: 700; 
				border-bottom: $border 1px solid;
				margin: 0 0 16px;
				padding: 0 0 4px;
                @media screen and (max-width:768px){
				    font-size: 22px;
                }
			}
			dd{
				position: relative;
				padding: 0 0 0 120px;
				margin: 0 0 12px;
				font-size: 15px;
                @media screen and (max-width:768px){
                  padding: 32px 0 0 0;
                }
				&:last-of-type{
					margin: 0;
					padding-bottom: 40px;
				}
				&:before{
					content: attr(data-title); 
					background: $blue;
					text-align: center;
					width: 100px;
					color: #FFF;
					padding: 2px 0;
					font-weight: 700;
					position: absolute;
					top: 2px;
					left: 0;
					font-size: 13px;
					border-radius: 4px;
				}
			}
		}
	}
	.resson-description{
		.text{
			font-size: 15px;
		}
		.list{
			margin: 20px 0 0;
			@include flex();
			li{
				width: 31.5%;
				background: #FFF; 
				border-radius: 4px;
				overflow: hidden;
				box-shadow: 0 6px 16px rgba(#000,.08);
                @media screen and (max-width:768px){
                    width: 100%;
					margin-bottom: 24px;
                }
				dl{
					dt{
						font-size: 18px;
						font-weight: 700;
						text-align: center;
						background: $blue;
						color: #FFF;
						padding: 8px 0;
					}
					dd{
						padding: 15px 32px;
						font-size: 16px;
					}
				}
			}
		}
	}
	.teacher-box{
        .contact{
            text-align: center;
            margin-top: 32px;
			margin-bottom: 8px;
        }
		.teacher{
			margin-bottom: 32px;
			figure{
				width: 30%;
				float: left;
                @media screen and (max-width:768px){
                    width: 100%;
					margin-bottom: 24px;
					float: none;
                }
				img{
					width: 100%;
				}
			}
			dl{
				width: 70%;
				padding: 0 0 0 40px;
				float: right;
                @media screen and (max-width:768px){
                    width: 100%;
					padding: 0;
                }
				dt{ 
					font-size: 22px;
					font-weight: 700;
					margin: 0 0 12px;
					padding: 0 0 10px;
					border-bottom: $border 1px solid;
					width: 85%;
				}
				dd{
					margin: 0 0 8px;
					&:before{
						content: attr(data-year);
						width: 80px;
						padding: 4px 0;
						background: $blue;
						color: #FFF;
						display: inline-block;
						text-align: center;
						margin: 0 16px 0 0;
					}
				}
			}
		}
	}
}
	.fitness-price-list{
		@include flex();
		li{
			width: 48.5%;
			margin: 0 0 32px;
			list-style: none;
			background: #FFF;
			padding: 25px;
            @media screen and (max-width:768px){
                width: 100%;
            }
			&.wide{
				width: 100%;
			}
			&.border-red{
					border: 1px solid $red;
				}
			dl.price{
				font-weight: 700;
				dt{
					font-size: 20px;
					font-weight: 700;
					margin: 0 0 12px;
					padding: 0 0 12px;
					border-bottom: $border 1px solid;
                    @media screen and (max-width:768px){
                        text-align: center;
                    }
				}
				dd{
					font-size: 16px;
                    @media screen and (max-width:768px){
                        text-align: center;
                    }
					strong{
						display: inline-block;
						font-size: 40px;
						line-height: 1;
						color: $red;
						margin: 0 8px;
                        @media screen and (max-width:768px){
                            font-size: 32px;
                        }
					}
				}
			}
			dl.desc{
				margin: 16px 0 0;
				dt{
					background: $normal;
					color: #FFF;
					padding: 4px 24px;
					display: inline-block;
                    @media screen and (max-width:768px){
                        width: 100%;
                        text-align: center;
                    }
				}
				dd{
					margin: 8px 0 0;
				}
			}
		} 
	}
	p.mini{
		font-size: 12px;
	}
	dl.register{
		background: #FFF;
		padding: 25px;
		margin: 0 0 24px;
		dt{
			font-size: 20px;
			font-weight: 700;
			margin: 0 0 12px;
		}
		dd{
			&:before{
				content: '■';
				color: $normal;
				display: inline-block;
				margin: 0 8px 0 0;
			}
		}
	}