@charset "utf-8";

.header-swim{
	i.circle{
		$size:94px;
		width: $size;
		height: $size;
		position: fixed;
		top: 20px;
		right: 20px;
		z-index: 100;
		top: 32px; 
		right: 32px;
		border: $blue 1px solid;
		background: $blue url(../../../images/common/swim_nav_border.png) no-repeat center;
		background-size: 46px;
		border-radius: 50%;
		box-shadow: 0 0 40px rgba(#000,.32);
		cursor: pointer;
		@include transition(160ms);
        @media screen and (max-width:768px){
            width: 80px;
            height: 80px; 
        } 
		&.cross{
			background: $blue url(../../../images/common/swim_nav_border_cross.png) no-repeat center;
		}
		&:hover{
			transform: scale(1.2);
			border: rgba(#FFF,.64) 8px solid;
			background-size: 32px;
		}
	}
	.global-swim{
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		width: 100%;
		height: 100%;
		background: rgba(#FFF,.96);
		@include flex();
		align-items: center;
		justify-content: center;
		ul{
			li{
				margin: 0 0 56px;
				text-align: center;
				&:last-child{
					margin: 0;
				}
				a{
					color: $normal;
					font-size: 32px;
					font-weight: 700;
					line-height: 1;
					&:before{
						content: attr(data-eng);
						font-size: 11px;
						color: $blue;
						display: block;
						letter-spacing: .12em;
						margin: 0 0 12px;
					}
					&:hover{
						opacity: .64;
					}
				}
			}
		}
	}
}

