.page-class{
	.table-style{
		tbody{
			tr{
				th,td{
					font-size: 15px;
					border: rgba(darken($blue,.50%),.64) 1px solid;
				}
				th{
					background: rgba($blue,.72);
					color: #FFF;
				}
				&.color1 td{
					background: rgba($blue,.12);
				}
				&.color2 td{
					background: rgba($blue,.16);
				}
			}
		}
		&.stripe{
			tbody{
				tr{
					&:nth-child(2n+2){
						td{
							background: rgba($blue,.08);	
						}
					}
				}
			}
		}
	}
}
