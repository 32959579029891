.header-index{
    background: $glay;
	padding: 48px 0;
    text-align: center;
    position: relative;
	@media screen and (max-width:768px){
		padding: 24px 0;
	}
    .logo{
        display: inline-block;
        width: 280px;
		padding: 8px 0 0;
		@media screen and (max-width:768px){
			width: 200px;	
		}
        img{
            width: 100%;
        }
    }
	.text{
		font-size: 15px;
		line-height: 1.5;
		margin: 24px 0 0;
		color: #FFF;
		@media screen and (max-width:768px){
			margin: 16px 15px 0;
			font-size: 13px;
		}
	}
}
.footer-index{
    background: $glay;
    padding: 64px 0;
    color: #FFF;
    text-align: center;
	&.dark{
		background: #252525;
	}
	&.blue{
		background: $blue;
	}
    &.light-blue{
        background: $light-blue;
    }
	@media screen and (max-width:768px){
		padding: 32px 15px;	
	}
    a{
        color: #FFF;
    }
    .index-nav{
        margin: 0 0 32px;
		@media screen and (max-width:768px){
			margin: 0 0 24px;
			border: rgba(#000,.24) 1px solid;
			border-radius: 6px;
			overflow: hidden;
		}
        ul > li{
            list-style: none;
            display: inline-block;
			@media screen and (max-width:768px){
				display: block;
				border-bottom: rgba(#000,.24) 1px solid;
				&:last-child{
					border: none;
				}
				a{
					display: block;
					padding: 10px 15px;
					text-align: left;
					background: rgba(#000,.12) url(../../../images/common/white_arrow_2x.png) no-repeat right 15px center;
					background-size: 5px;
					font-size: 14px;
					&:hover{
						background: rgba(#000,.04);
					}
				}
			}
            &:after{
                content: '|';
                margin: 0 20px;
                display: inline-block;
				@media screen and (max-width:768px){
					display: none;
				}
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
        }
    }
    small.copy{
        display: block;
		@media screen and (max-width:768px){
			font-size: 9px;	
		}
    }
}

.page-index{
    padding: 40px 0 8px;
	@media screen and (max-width:768px){
		padding: 32px 15px 8px;	
	}
    .flex-list{
        @include flex();
        li{
            list-style: none;
            width: 48.5%;
            margin: 0 0 32px;
			@media screen and (max-width:768px){
				width: 100%;
				margin: 0 0 24px;
			}
            a{
                position: relative;
                color: #FFF;
				padding: 80px 0;
                @include flex();
                align-items: center;
                justify-content: center;
                background-position: center;
                background-size: cover;
				@media screen and (max-width:768px){
					padding: 48px 0;	
				}
                &:hover{
                    &:after{
                        opacity: .12;
                    }
                }
                @for $i from 1 through 4{
                    &.poster-#{$i}{
                        background-image: url('../../../images/index/photo_#{$i}.jpg');
                    }
                }
                dl{
                    position: relative;
                    z-index: 2;
                    text-align: center;
                    dt{
                        font-size: 32px;
                        font-weight: 700;
                        line-height: 1;
                        margin: 0 0 24px;
						text-shadow: 0 0 16px rgba(#000,.24);
						img{
							height: 120px;
							width: auto;
							@media screen and (max-width:768px){
								height: 88px;	
							}
						}
                    }
                }
                &:before{
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: rgba(#000,.4) 10px solid;
                    z-index: 2;
                }
                &:after{
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: rgba(#252525,.12);
                    z-index: 1;
                    @include transition(200ms);
                }
            }
            &.size-l{
				a{
					padding: 120px 0;
					@media screen and (max-width:768px){
						padding: 64px 0;	
					}
				}
            }
            &.col-red{
                a:before{
                    border: rgba($red,.4) 10px solid;
                }
                a:after{
                    background: rgba($red,.08);
                }
            }
            &.col-blue{
                a:before{
                    border: rgba($blue,.4) 10px solid;
                }
                a:after{
                    background: rgba($blue,.08);
                }
            }
            &.col-yellow{
                a:before{
                    border: rgba($yellow,.4) 10px solid;
                }
                a:after{
                    background: rgba($yellow,.08);
                }
            }
        }
    }
}
