.page-interview{
	.interview-box{
		border: #eee 8px solid;
		padding: 32px;
		margin: 0 0 32px;
        @media screen and (max-width:768px){
            padding: 20px;
            border: #eee 4px solid;
        }
		&:last-of-type{
			margin: 0;
		}
		dl.names{
			position: relative;
			border-bottom: $border 1px solid;
			padding: 0 0 24px;
			margin: 0 0 24px;
			dt{
				font-size: 28px;
				line-height: 1;
				padding-right: 30%;
                @media screen and (max-width:768px){
                    padding: 0;
                    line-height: normal;
                    font-size: 18px;
                }
			}
			dd{
				font-size: 28px;
				font-weight: 700;
				line-height: 1;
				position: absolute;
				top: 0;
				right: 0;
                @media screen and (max-width:768px){
                    position: static;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 16px;
                }
				&:before{
					content: attr(data-job);
					background: $red;
					padding: 8px 16px;
					display: inline-block;
					color: #FFF;
					font-size: 13px;
					vertical-align: middle;
					margin: 0 20px 0 0;
					transform: translateY(-2px);
                    @media screen and (max-width:768px){
                        margin: 8px 0;
                    }
				}
			}
		}
		.photo{
			width: 30%;
			float: left;
            @media screen and (max-width:768px){
                width: 100%;
            }
			img{
				width: 100%;
			}
		}
		.text{
			width: 70%;
			float: right;
			padding-left: 40px;
			font-size: 16px;
            @media screen and (max-width:768px){
                width: 100%;
                padding: 0;
                margin: 16px 0 0;
                font-size: 14px;
            }
		}
	}
}

