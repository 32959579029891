.page-swim{
	section{
		background: #FFF;
	}
	.blue-bg{
		background: $blue;
		color: #FFF;
	}
	.sky-bg{
		background: #f3f9fb;
	}
	.swim-contact-bg{
		background: url(../../../images/swim/swim-contact-bg.jpg);
		background-size: cover;
		position: relative;
		&:before{
			content: '';
			background: #FFF;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: .88;
		}
	}
	.wave-text{
		text-align: center;
		font-size: 25px;
		font-weight: 700;
		color: #FFF;
		&:after{
			display: block;
			content: '';
			background: url(../../../images/swim/h_wave.png);
			width: 64px;
			height: 13px;
			margin: 12px auto 24px;
		}
		&.blue{
			color: $blue;
			&:after{
				background: url(../../../images/swim/h_wave_b.png);
			}
		}
	}
	.swim-fv{
		width: 100%;
		height: 100vh;
		position: relative;
		color: #FFF;
		background: none !important;
		&:after{
			content: '';
			background: url(../../../images/swim/wave.png) left top repeat-x;
			width: 100%;
			height: 88px;
			position: absolute;
			left: 0;
			bottom: 40px;
				animation: anime1 8s linear 0s infinite;
				@keyframes anime1 {
					0% {background-position: 0 0;} 
					100% {background-position: 1400px 0;}
				}		
		}
		.logo{
			width: 280px;
			position: absolute;
			bottom: 200px;
			left: 50%;
			transform: translateX(-50%);
			img{
				width: 100%;
			}
		}
		.marquee{
			$line: 40px;
			position: absolute;
			left: 0;
			bottom: 0;
			background: #aee3f7;
			padding: 0;
			height: $line;
			line-height: $line;
			color: $normal;
		}
	}
	.listable{
		@include flex();
		padding: 0 180px;
		@media screen and (max-width:769px){
			padding: 0;
		} 
		li{ 
			list-style: none;
			width: 48%;
			margin: 0 0 40px;  
            @media screen and (max-width:769px){
				width: 100%;
				margin: 0 0 32px;
            }
			figure{ 
				img{
					width: 100%;
				}
			}
			dl{
				background: $blue;
				color: #FFF;
				text-align: center;
				padding: 15px 0;
				font-weight: 700;
				dt{
					font-size: 24px;
					&:after{
						content: '';
						width: 48px;
						height: 1px;
						background: #FFF;
						margin: 8px auto 8px;
						display: block;
					}
				}
				dd{
					font-size: 18px;
					&.btn-area{
						margin: 12px 0 0;
						text-align: center;
						a{
							color: $blue;
						}
					}
				}
			}
		}
	}
	.taiken{
		text-align: center;
		dt{
			font-size: 24px;
			font-weight: 700;
			color: $blue;
			margin: 0 0 16px;
		}
	}
	.taiken-culture{
		text-align: center;
		margin-top: 24px;
	}
	.kussion{
		padding: 180px 0; 
		background: url(../../../images/swim/swim_kussion.jpg) no-repeat center bottom;
		background-size: cover;
		color: #FFF;
	}
	
	.blueborder{
		border: $blue 4px solid;
	}
	.fig{
		width: 100%;  
		background: #fff;
    	margin: 0 0 4%;
		padding: 16px;
		box-shadow: 8px 8px 14px 0px rgba(0,0,0,.09);
		img{
			width: 40%;
			margin: 0 30%;
            @media screen and (max-width:768px){
                width: 80%; 
                margin: 0 10%;
            } 
		}
	}
}
