.footer-common{
    background: $glay;
    padding: 64px 0;
    color: #FFF;
    text-align: center;
	&.red{
		background: $red;
	}
	&.yellow{
		background: $yellow;
	}
	@media screen and (max-width:768px){
		padding: 32px 15px;	
	}
    a{
        color: #FFF;
    }
	.logo{
		width: 240px;
		margin: 0 auto 24px;
		img{
			width: 100%;
		}
	}
    .nav-common{
        margin: 0 0 32px;
		@media screen and (max-width:768px){
			margin: 0 0 24px;
			border: rgba(#000,.24) 1px solid;
			border-radius: 6px;
			overflow: hidden;
		}
        ul > li{
            list-style: none;
            display: inline-block;
			margin: 0 24px;
			font-size: 15px;
            @media screen and (max-width:768px){
                list-style: none;
                display: inline-block;
                margin: 16px auto;
                font-size: 15px;
                width: 40%;
            }
			a{
				&:hover{
					opacity: .44;
				}
			}
			i{
				font-size: 32px;
				display: inline-block;
				margin: 4px 0 0;
				color: #FFF;
			}
        }
    }
    &.yellow,
    &.red{
        @media screen and (max-width:768px){
            .nav-common{
                >ul{
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}