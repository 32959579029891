@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#222;
$link:#D02;
$border:#d5d5d5;

$red : #df1214;
$blue : #35baeb;
$light-blue : #36BAEB;
$yellow: #e7ab42;
$glay: #595b61;

$d-red: #df1214;

/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$pc-head:80px;
$sp-head:60px;

[v-cloak] {
  display: none;
}

@media screen and (min-width:769px){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:768px){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/header_swim";
@import "common/footer";
@import "common/breadcrumb";
@import "page/index";
@import "page/company";
@import "page/recruit";
@import "page/fitness";
@import "page/calture";
@import "page/class";
@import "page/interview";
@import "page/swim";
.motion-animate{
	opacity: 0;
	transform: translateY(-40px);
	transition: all .8s cubic-bezier(0.645, 0.045, 0.355, 1);
	&.active{
		transform: translateY(0);
		opacity: 1;
	}
}
.dark{
    background: rgba(0,0,0,.8);
    color: #fff;
}

.MODULE{
	&.bg{
		background: #f2f2f2;
	}
	.btn-style{
		text-align: center;
		@include transition(200ms);
        @media screen and (max-width:768px){
            width: 80%;
        }
		&:hover{
			opacity: .88;
		}
		&.input{
			border: none;
			font-size: 18px;
			font-weight: 700;
			padding: 8px 64px !important;
			cursor: pointer;
            @media screen and (max-width:768px){
                font-size: 16px;
                padding: 8px 16px!important;
            }
		}
		&.red{
			background: $red;
			color: #FFF;
		}
		&.white{
			background: #FFF;
			color: $normal;
		}
		&.blue{
			background: $blue;
			color: #FFF;
		}
		&.yellow{
			background: $yellow;
			color: #FFF;
		}
		&.style-ghost{
			display: inline-block;
			border: #FFF 2px solid;
			padding: 4px 24px;
			color: #FFF;
		}
		&.style-radius{
			display: inline-block;
			padding: 4px 24px;
			border-radius: 8px;
		}
		&.style-circle{
			display: inline-block;
			padding: 4px 24px;
			border-radius: 999px;
		}
		&.style-square{
			display: inline-block;
			padding: 4px 24px;
		}
		i{
			display: inline-block;
			margin: 0 0 0 12px;
		}
	} 
	&.wapper{
		padding: 20px 0;
	}
	&.wrap{
		padding: 80px 0;
		@media screen and (max-width:769px){
			padding: 40px 0;
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
	}
	
	h2.bottom-border{
		font-size: 36px;
		font-weight: 700;
		text-align: center;
		margin: 0 0 32px;
        @media screen and (max-width:768px){
            font-size: 24px;
        }
		&.red{
			&:after{
				background: $red;
			}
		}
		&.yellow{
			&:after{
				background: $yellow;
			}
		}
		&:after{
			content: '';
			width: 56px;
			height: 4px;
			background: #000;
			margin: 12px auto 0;
			display: block;
		}
	}
	h2.center-border{
		text-align: center;
		font-size: 20px;
		font-weight: 700;
		color: #fff;
		position: relative;
		margin: 0 0 32px;
        &.black{
            color: $normal;
        }
        &.red:after{
            background: $red;
        }
        &.gorld{
			color: #fff;
			&::after{
            	background: #d29f31;
			}
		}
		&:before{
			content: attr(data-eng);
			font-size: 34px;
			display: block;
			margin: 0 0 20px;
		}
		&:after{
			content: '';
			width: 144px;
			height: 1px;
			background: #FFF;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(8px);
		}
        &.two_lines{
            &:after{
                @media screen and (max-width:768px){
                    top: 64%;
                }
            }
        }
	} 
	h2.bar-magin{
		@media only screen and (max-width:768px){
            &:after{
                top: 64%;
            }
		}
	}
	h3.borders-gorld{
    	width: 100%;
		font-size:20px;
		font-weight:700;
		line-height:1.2;
		padding:0 0 8px 16px;
		margin:0 0 20px;
		position:relative;
		top:0;
		left:0;
    	border-bottom: 4px solid;
        border-image: linear-gradient(135deg, #a79756, #faf8c5, #a79756);
        border-image-slice: 1;
		&:before{
			content:'';
			width:4px;
			height:80%;
			background: rgba(0,0,0,.8);
			position:absolute;
			top:0;
			left:0;
		}
	}
	h3.borders{
    	width: 100%;
		font-size:20px;
		font-weight:700;
		line-height:1.2;
		padding:0 0 8px 16px;
		margin:0 0 20px;
		position:relative;
		top:0;
		left:0;
		border-bottom:rgba(#000,.08) 1px solid;
        &.bold{
            border-bottom:$normal 2px solid;
        }
        &.black{
			color: $normal;
            &:before{
                background: $normal;
            }
        }
        &.white{
			color: #fff;
			border-bottom:#d29f31 2px solid;
            &:before{
                background: #d29f31;
            }
        }
		&.yellow{
			&:before{
				background: $yellow;
			}
		}
		&.blue{
			&:before{
				background: $blue;
			}
		}
        &.light-blue{
            &:before{
                background: $light-blue;
            }
        }
		@media only screen and (max-width:768px){
			font-size: 17px;
			margin: 0 0 12px;
		}
		&:before{
			content:'';
			width:4px;
			height:80%;
			background: $red;
			position:absolute;
			top:0;
			left:0;
		}
	}
	.table-style{
		border: $border 1px solid;
		width: 100%;
		border-collapse: collapse;
        @media screen and (max-width:768px){ 
            width: 100%;
            margin: auto;
            display: block;
            overflow-x: scroll;
            white-space: nowrap;
        }
		tr{
			th,td{
				padding: 15px;
				text-align: left;
				border: $border 1px solid;
				background: #FFF;
				vertical-align: middle;
			}
			th{
				width: 20%;
				background: #f2f2f2;
				&.hiss{
					&:after{
						content: '※';
						color: darken($red , 8%);
						font-weight: 700;
						display: inline-block;
						margin:  0 0 0 12px;
					}
				}
			}
		}
        &.contact{
            @media screen and (max-width:768px){
                display: table;
                tr{
                    display: flex;
                    flex-direction: column;
                    th,
                    td{
                        width: 100%;
                    }
                }
            }
        }
	}
	.items-list{
		@include flex();
		li{
			width: 48%;
			margin: 0 0 4%;
			padding: 148px 0 0;
			background-color: #EEE;
			background-size: cover;
            @media screen and (max-width:768px){
                width: 100%;
				margin: 0 0 32px;
	            &:last-child{
                    margin: 0;
            	}
            }
			dl{
				background: rgba(#000,.75);
				padding: 15px;
				color: #FFF;
				dt{
					font-size: 20px;
					font-weight: 700;
				}
				dd{
					font-size: 12px;
				}
			}
		}
	}
	.gmap{
		iframe{
			width: 100%;
			height: 400px;
			vertical-align: bottom;
            @media screen and (max-width:768px){
                height: 560px;
            }
		}
	}
	.acc-data{
		@extend .clearfix;
		figure{
			width: 50%;
			float: left;
			padding-right: 56px;
            @media screen and (max-width:768px){
              width: 100%;
			  padding: 0;
              margin-bottom: 24px;
			  float: none;
            }
			img{
				width: 100%;
			}
		}
		.shopdata{
			width: 50%;
			float: right;
			color: #FFF;
            @media screen and (max-width:768px){
              width: 100%;
			  padding: 0;
			  float: none;
            }
			&.swim{
				color: $normal;
				dl{
					dt{
						border-color: $border;
						&:after{
							color: $blue;
						}
					}
					dd{
						&.dec{
							&:before{
								background: #EEE;
								color: $normal;
							}
						}
					}
				}
			}
			&.none-shadow{
				dl{
					text-shadow: none;
					dd{
						&.dec{
							&:before{
								box-shadow: none;
							}
						}
					}
				}
			}
			dl{
				text-shadow: 0 0 8px rgba(#000,.56);
				margin: 0 0 24px;
				&:last-of-type{
					margin: 0;
				}
				dt{
					font-size: 24px;
					font-weight: 700;
					margin: 0 0 12px;
					padding: 0 0 8px;
					border-bottom: #FFF 2px solid;
					&:after{
						content: attr(data-eng);
						font-size: 12px;
						color: $d-red;
						display: inline-block;
						margin: 0 0 0 24px;
					}
				}
				dd{
					margin: 0 0 12px;
					&.dec{
						font-size: 16px;
						font-weight: 700;
						&:before{
							content: attr(data-text);
							background: #999;
							padding: 2px 0;
							width: 140px;
							display: inline-block;
							margin: 0 16px 0 0;
							text-align: center;
							font-size: 18px;
							text-shadow: none;
							box-shadow: 0 0 8px rgba(#000,.56);
                            @media screen and (max-width:768px){
                                display: block;
								margin-bottom: 8px;
								width: auto;
								margin: 0;
                            }
						}
					}
					&:last-of-type{
						margin: 0;
					}
				}
			}
		}
	}
	ul.contacts{
		@include flex();
		li{
			width: 48%;
			list-style: none;
			background: #FFF;
            @media screen and (max-width:768px){
              width: 100%;
			  margin-bottom: 24px;
				&:last-child{
					margin-bottom: 0;
				}
            }
			&.tel{
				dl{
					dt{
						background: #5fdf12;
					}
					dd{
						font-weight: 700;
						font-size: 25px;
						a{
							color: $normal;
							display: block;
							margin: 8px 0 0;
                            @media screen and (max-width:768px){
								margin: 0;
                            }
						}
						strong{
							font-size: 38px;
							display: inline-block;
							margin: 0 0 0 16px;
							line-height: 1;
                            @media screen and (max-width:768px){
								font-size: 34px;
								margin: 0;
                            }
						}
					}
				}
			}
			&.contact{
				dl{
					dt{
						background: $d-red;
					}
					dd{
						a{
							color: #FFF;
							background: $d-red url(../../images/common/white_arrow_2x.png) no-repeat right 15px center;
							background-size: 8px;
							display: block;
							font-size: 16px;
							font-weight: 700;
							padding: 15px 0;
						}
					}
				}
			}
			dl{
				dt{
					font-size: 16px;
					background: #EEE;
					color: #FFF;
					text-align: center;
					padding: 4px 0;
				}
				dd{
					text-align: center;
					padding: 8px;
				}
			}
		}
	}
    .none{
        display: none;
        @media screen and (max-width:768px){
            display: block;
        }
    }
}

.marquee{
	width:100%;
	padding:15px 0;
	overflow:hidden;
	p{
		padding-left:100%;
		display:inline-block;
		white-space:nowrap;
		animation-name:marquee;
		animation-timing-function:linear;
		animation-duration:10s;
		animation-iteration-count:infinite;
		@keyframes marquee {
		  from   { transform: translate(0%);}
		  99%,to { transform: translate(-100%);}
		}
	}
}

video#fixed{
	position: fixed;
	top: 0;
	left: 0;
	min-width: 100%;
	width: auto;
	min-height: 100%;
	height: auto;
	z-index: -1;
}


.fade-enter-active, .fade-leave-active {
	will-change: opacity;
	transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fade-enter, .fade-leave-to {
	opacity: 0
}

.zoom-enter-active, .zoom-leave-active {
  transition: all .6s cubic-bezier(.77, 0, .175, 1);
  opacity: 1;
  transform: scale(1)
}

.zoom-enter, .zoom-leave-to {
  opacity: 0;
  transform: scale(1.2)
}

.center{
  	text-align: center;
	margin-top: 24px;
}
.calendar{
    margin-top: 32px;
    text-align: center; 
    h4{
        font-size: 24px;
        margin-bottom: 16px;
        text-align: center;
        position: relative;
		&::before{
			content: "";
			width: 96px;
			height: 2px;
			background: $blue;
			position: absolute;
			bottom: -4px;
		}
    }
@media screen and (max-width:768px){
    iframe{
		width: 100%;
    }
}

}
.head{
	font-size: 22px;
	margin-bottom: 24px;
	padding-bottom: 8px; 
	font-weight: bold;
	border-bottom: solid 3px $blue;
	display: inline-block;
	}