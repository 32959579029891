.secondview{
	width: 100%;
	position: relative;
	background-size: cover;
	background-position: center;
	&:before{
		content: '';
		background: #000;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: .32;
		@media only screen and (max-width:768px){
			opacity: .5;	
		}
	}
	.display{
		@include flex();
		align-items: center;
		justify-content: center;
		height: 380px;
		@media only screen and (max-width:768px){
			height: 140px;	
		}
	}
	h2{
		color: #FFF;
		font-size: 40px;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		text-shadow: 0 0 8px rgba(#000,.32);
		@media only screen and (max-width:768px){
			font-size: 28px;
		}
		&:after{
			content: attr(data-eng);
			font-size: 14px;
			margin: 16px 0 0;
			display: block;
			letter-spacing: .12em;
			@media only screen and (max-width:768px){
				font-size: 12px;
				margin: 8px 0 0;
			}
		}
	}
}

#breadcrumb{
	padding: 14px 0;
	
	background: #f2f2f2;
	@media only screen and (max-width:768px){
		padding: 15px;

	}
	ol{
		background:url(/images/common/home_icon_2x.png) no-repeat left 6px;
		background-size: 12px;
		padding:0 0 0 24px;
		@media only screen and (max-width:768px){
			background:url(/images/common/home_icon_2x.png) no-repeat left center;
			background-size: 12px;
			padding: 0 0 0 20px;
		}
		li{
			list-style:none;
			float:left;
			font-size:13px;
			background:url(/images/common/arrow_2x.png) no-repeat right 8px;
			background-size: 5px;
			padding:0 20px 0 0;
			margin:0 16px 0 0;
			@media only screen and (max-width:768px){
				background:url(/images/common/arrow_2x.png) no-repeat right center;
				background-size: 3px;
				font-size:10px;
				padding:0 15px 0 0;
				margin:0 10px 0 0;
			}
			&:last-child{
				padding:0;
				margin:0;
				background:none;
			}
			a{
				color:$normal;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}
