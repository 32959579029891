@charset "utf-8";
@media screen and (max-width:1220px){
	.res{
        i.circle{ 
            $size:94px;
            width: $size; 
            height: $size;
            position: fixed;
            top: 16px;
            right: 24px;
            z-index: 100;
            top: 32px; 
            right: 32px;
            border: $red 1px solid;
            background: $red url(../../../images/common/swim_nav_border.png) no-repeat center;
            background-size: 46px;
            border-radius: 50%;
            box-shadow: 0 0 40px rgba(#000,.32);
            cursor: pointer;
            @include transition(160ms);
            @media screen and (max-width:768px){
                width: 80px;
                height: 80px; 
            } 
            &.cross{
                background: $red url(../../../images/common/swim_nav_border_cross.png) no-repeat center;
            }
            &:hover{
                transform: scale(1.2);
                border: rgba(#FFF,.64) 8px solid;
                background-size: 32px;
            }
        }
        .global-swim{
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;
            width: 100%;
            height: 100%;
            background: rgba(#FFF,.96);
            @include flex();
            align-items: center;
            justify-content: center; 
            ul{
                li{
                    margin: 0 0 24px;
                    text-align: center;
                    &:last-child{
                        margin: 0;
                    } 
                    a{
                        color: $normal;
                        font-size: 32px;
                        font-weight: 700;
                        line-height: 1;
                        &:before{
                            content: attr(data-eng);
                            font-size: 11px;
                            color: $red;
                            display: block;
                            letter-spacing: .12em;
                            margin: 0 0 12px;
                        }
                        &:hover{
                            opacity: .64;
                        }
                    }
                }
            }
        }
    }
	
	.res-recruit{
			display: none;
		@media screen and (max-width:1220px){
			display: block;
		}
        i.circle{  
            $size:94px;
            width: $size; 
            height: $size;
            position: fixed;
            top: 16px;
            right: 24px;
            z-index: 100;
            top: 32px; 
            right: 32px;
            border: $yellow 1px solid;
            background: $yellow url(../../../images/common/swim_nav_border.png) no-repeat center;
            background-size: 46px;
            border-radius: 50%;
            box-shadow: 0 0 40px rgba(#000,.32);
            cursor: pointer;
            @include transition(160ms);
            @media screen and (max-width:1220px){
                width: 80px;
                height: 80px; 
            } 
            &.cross{
                background: $yellow url(../../../images/common/swim_nav_border_cross.png) no-repeat center;
            }
            &:hover{
                transform: scale(1.2);
                border: rgba(#FFF,.64) 8px solid;
                background-size: 32px;
            }
        }
        .global-swim{
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;
            width: 100%;
            height: 100%;
            background: rgba(#FFF,.96);
            @include flex();
            align-items: center;
            justify-content: center; 
			@media screen and (min-width:1221px){
				display: none !important;		
			}
            ul{
                li{
                    margin: 0 0 24px;
                    text-align: center;
                    &:last-child{
                        margin: 0;
                    } 
                    a{
                        color: $normal;
                        font-size: 32px;
                        font-weight: 700;
                        line-height: 1;
                        &:before{
                            content: attr(data-eng);
                            font-size: 11px;
                            color: $red;
                            display: block;
                            letter-spacing: .12em;
                            margin: 0 0 12px;
                        }
                        &:hover{
                            opacity: .64;
                        }
                    }
                }
            }
        }
    }	
	
} 
.header-common{
    background: #000;
	color: #FFF;
	padding: 24px 0;
    text-align: center;
    position: relative;
	width: 100%;
	z-index: 1000; 
	&.abs {
		position: absolute;
	}
	&.yellow{ 
		background: rgba($yellow,.88);
	}
	&.fitness{
		background: rgba(#FFF,.2);
            @media screen and (max-width:768px){
				background: rgba(#FFF,0);
            } 
		&:before{
			content: '';
			width: 100%;
			height: 4px;
			background: $d-red;
			position: absolute;
			top: 0;
			left: 0;
            @media screen and (max-width:768px){
				display: none;
            }
		}
	}
	&.red{
		background: $red;
	}
	@media screen and (max-width:768px){
		padding: 34px 0;
	}
	.logoTop{
        width: 240px;
		float: left;
		@media screen and (max-width:768px){
			width: 200px;
			display: none;
		}
        img{
            width: 100%;
        }		
	}
    .logo{
        width: 240px;
		float: left;
		@media screen and (max-width:768px){
			width: 200px;
		    margin-left: 32px;
		}
        img{
            width: 100%; 
        }
	}

	.catch{
		float: left;
		margin: 16px 0 0 24px;
		font-size: 16px;
		font-weight: 700; 
		line-height: 1;
		@media screen and (max-width:768px){
			margin: 0;
            position: relative;
            top: 38px;
            right: 200px;
		}
	}
	.gnavi{
		float: right;
		margin: 3px 0;
		@media screen and (max-width:1220px){
			display: none;	
		} 
		&.eng-sub{
			margin: 0;
			ul{
				li{
					a{
						font-size: 15px;
						line-height: 1;
						&:after{
							content:attr(data-eng);
							font-size: 10px;
							display: block;
							text-align: center;
							margin: 8px 0 0;
							font-weight: 500;
						}
					}
				}
			}
		}
		ul{
			li{
				list-style: none;
				float: left;
				font-size: 16px;
				font-weight: 700;
				margin: 0 48px 0 0;
				&:last-child{
					margin: 0;
				}
				a{
					display: block;
					color: #FFF;
					&:hover{
						opacity: .64;
					}
				}
			}
		}
	}
}
.header-swimming{
	color: #FFF;
    text-align: center;
	width: 100%;
	z-index: 1000;
	&.abs {
		position: absolute;
	}
	&.fitness{
		background: rgba(#FFF,.2);
		&:before{
			content: '';
			width: 100%;
			height: 4px;
			background: $light-blue;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	@media screen and (max-width:768px){
		padding: 24px 0;
	}
    .logo{
        position: absolute;
        left: 24px;
        top: 24px;
        width: 120px;
		float: left;
		@media screen and (max-width:768px){
			width: 200px;	
		}
        img{
            width: 100%;
        }
    }
	.catch{
		float: left;
		margin: 16px 0 0 24px;
		font-size: 16px;
		font-weight: 700;
		line-height: 1;
	}
	.gnavi{
		position: absolute;
        top: 36px;
        right: 140px;
        float: right;
        width: 91px;
        height: 91px;
        border-radius: 48px;
        background: url(../../images/common/responsive_nav_2x.png) no-repeat center top 13px,rgb(54, 186, 235);
        transition: all 150ms ease-in-out;
		&.eng-sub{
			margin: 0;
			ul{
                display: none;
                position: absolute;
                top: 64px;
                left: 0;
                width: 100%;
                background: #FFF;
                box-shadow: 0 24px 24px rgba(0,0,0,.32);
				li{
                    float: none;
                    width: auto;
                    margin: 0;
                    border-top: #EEE 1px solid;
					a{
						font-size: 15px;
						line-height: 1;
						&:after{
							content:attr(data-eng);
							font-size: 10px;
							display: block;
							text-align: center;
							margin: 8px 0 0;
							font-weight: 500;
						}
					}
				}
			} 
		}
        &.addClose{
            background: url(../../images/common/responsive_nav_2x.png) 0 -32px no-repeat,rgb(54, 186, 235);
            ul{
                position: absolute;
                top: 64px;
                background: #fff;
                display: flex!important;
                width: 1120px;
                right: 96px;
            }
        }
	}
}

