
.page-fitness{
	background: rgba(#000,.5);
    &.white{
        background: #FFF;
    }
	.fitness-fv{
		width: 100%;
		height: 100vh;
		position: relative;
		color: #FFF;
		&:after{
			content: '';
			background: url(../../../images/common/sc_icon.png) no-repeat;
			width: 65px;
			height: 93px;
			position: absolute;
			left: 50%;
			bottom: 88px;
			transform: translateX(-50%);
		}
		.logo{
			width: 400px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-60%);
            @media screen and (max-width:768px){
    			width: 300px;
            }
			img{
				width: 100%;
			}
		}
		.marquee{
			position: absolute;
			left: 0;
			bottom: 0;
			background: rgba(#FFF,.2);
		}
	}
	
	.mes-box{
		width: 660px; 
		margin: 0 auto;
		background: $d-red;
		color: #FFF;
		padding: 32px 56px;
        @media screen and (max-width:768px){
            width: 100%;
			padding: 32px; 
        } 
		dt{
			font-size: 25px;
			margin: 0 0 12px;
			text-align: center;
		}
	}
	.photo-list{
		@include flex();
		li{
			width: 31%;
			list-style: none;
			background-position: center;
			background-size: cover;
            @media screen and (max-width:900px){
                width: 100%;
                margin-bottom: 24px;
				&:last-child{
					margin-bottom: 0;
				}
			}
			a{
				display: block;
				border: #FFF 4px solid;
				background: rgba(#000,.5);
				color: #FFF;
				@include transition(200ms);
				&:hover{
					border-color: $d-red;
				}
			}
			dl{
				text-align: center;
				padding: 32px 0;
				dt{

					font-size: 27px;
					&:after{
						content: '';
						width: 24px;
						height: 1px;
						background: #FFF;
						margin: 12px auto;
						display: block;
					}
				}
			}
		}
	}
	.bnr-text{
		background: $d-red;
		position: relative;
        @media screen and (max-width:768px){
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            margin: auto; 
        } 
		&:before{
			content: '';
			width: 50%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: url(../../../images/fitness/bnr_txt_bg.jpg) center;
			background-size: cover;
            @media screen and (max-width:768px){
                width: 100%;
                height: 50%;
            } 
		}
		.txt{
			padding: 64px 32px;
			color: #FFF;
			width: 50%; 
			margin: 0 0 0 50%;
            @media screen and (max-width:768px){
                padding: 16px 32px; 
                width: 100%;
                margin: 70% 0 0 0;
            } 
			.btn-style{
				color: $d-red;
			}
		}
	}
    
    .traning-box{
        @include flex();
        @media screen and (max-width:768px){
            flex-direction: column;
        }
        .harf{
			ul{
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap; 
				li{
                    padding: 16px;
                    background: rgba(000,000,000,.8);
                    color: #fff;
                    font-weight: bold;
                    border-radius: 8px;
					cursor: pointer;
					&.active{ 
						background: #D29F31;
						color: #111; 
					} 
				}
			}
            width: 48.5%; 
            img{
                width: 100%;
            } 
            width: 48.5%;
            @media screen and (max-width:768px){
                width: 100%;
            }
            img{
                width: 100%;
            }
            .mb-4{
                @media screen and (max-width:768px){
                    margin: 16px 0;
                }
            }
				.price-traning-con{ 
                  border: #eee 8px solid;
                  padding: 25px;
                  font-size: 15px;
                  @media screen and (max-width:768px){
                      margin: 24px 0 0;
                  }
                  dt{ 
                      font-size: 20px;
                      span{
                          font-size: 16px;
                          padding-left: 8px;
                      }
                      @media screen and (max-width:768px){
                          text-align: center;
                      }
                      &:after{
                          content: ''; 
                          width: 40px;
                          height: 1px;
                          background: $red;
                          display: block;
                          margin: 8px 0 16px;
                          @media screen and (max-width:768px){
                              margin: 8px auto 16px;
                          }
                      }
                  } 
                  dd{
                      margin: 0 0 8px;
                      font-size: 24px;
                      font-weight: 700;
                      display: flex;
                      align-items: center;
                      @media screen and (max-width:768px){
                          font-size: 18px;
                          text-align: center;
                          display: inherit;
                          margin: 0 0 24px;
                      }
                      strong{
                          font-size: 40px;
                          line-height: 1;
                          @media screen and (max-width:768px){
                              font-size: 32px;
                          }
                      }
                      &:last-of-type{
                          margin: 0;
                      }
                      &:before{
                          content: attr(data-title);
                          display: inline-block;
                          padding: 2px 0;
                          width: 120px;
                          background: rgba(000,000,000,.8);;
                          color: #FFF;
                          text-align: center; 
                          margin: 0 12px 0 0;
                          font-weight: 700;
                          font-size: 16px;
                          @media screen and (max-width:768px){
                              display: block;
                              margin: 0 auto 10px;
                              font-size: 12px;
                          }
                      }
                  }
				}
            .price-traning{
                border: #d29f31 1px solid;
                padding: 25px;
                font-size: 15px;
                @media screen and (max-width:768px){
                    margin: 24px 0 0;
                }
                dt{
					&.gorld{
						color: #d29f31;
                        &:after{
                            background:#d29f31;
                        }						
					}
                    font-size: 20px;
					span{
						font-size: 16px; 
						padding-left: 8px;
                        @media screen and (max-width:768px){
                            padding-left: 0;
                        }
					}
                    @media screen and (max-width:768px){
                        text-align: center;
                    }
                    &:after{
                        content: ''; 
                        width: 40px;
                        height: 1px;
                        background: $red;
                        display: block;
                        margin: 8px 0 16px;
                        @media screen and (max-width:768px){
                            margin: 8px auto 16px;
                        }
                    }
                }
                dd{
                    margin: 0 0 8px;
                    font-size: 24px;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    @media screen and (max-width:768px){
                        font-size: 18px;
                        text-align: center;
                        display: inherit;
                        margin: 0 0 24px;
                    }
                    strong{
                        font-size: 40px;
                        line-height: 1;
                        @media screen and (max-width:768px){
                            font-size: 32px;
                        }
                    }
                    &:last-of-type{
                        margin: 0;
                    }
                    &:before{
                        content: attr(data-title);
                        display: inline-block;
                        padding: 2px 0;
                        width: 120px;
                        background: #c0c0c0;
                        color: $normal;
                        text-align: center; 
                        margin: 0 12px 0 0;
                        font-weight: 700;
                        font-size: 16px;
                        @media screen and (max-width:768px){
                            display: block;
                            margin: 0 auto 10px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    .traning-menu-list{
        margin: 48px 0 0;
        @include flex();
        @media screen and (max-width:768px){
            flex-direction: column;
        }
        li{
            border: #d29f31 1px solid;
            padding: 20px;
            width: 31%;
            &:last-child(){
                @media screen and (max-width:768px){
                    margin: 0;
                } 
            }
            @media screen and (max-width:768px){
                width: 100%;
                margin: 0 0 24px;
            }
            figure{
                img{ 
                    width: 100%;
                }
            }
            dl{
                dt{
                    text-align: center;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 1.5;
                    margin: 24px 0 15px;
                    &:before{
                        content: attr(data-eng);
                        color: $red; 
                        display: block;
                    }
					&.heading{
						font-size: 24px;
                        &:before{
                            content: attr(data-eng);
                            color: #d29f31; 
                            display: block;
                        }
					}
                }
            }   
        }
    }
	.trainer-wrap{
		margin-top: 32px;
    	border: #eee 8px solid;
		
		h3{
            margin: 24px 0px 0px 24px;
            font-size: 24px;
            color: #111;
            font-weight: bold;
			position: relative;
			font-weight: 700;
			&::after{
                content: "";
                display: block;
                width: 48px;
                height: 1px;
                background: #df1214;
                position: absolute;
                bottom: -8px;
                left: 2px;
			}
		}
	}
	.training-wrap{
			border: #d29f31 1px solid !important;
			margin-top: 32px;
    		padding: 24px;
		h3{
            font-size: 24px;
            color: #d29f31;
            padding: 0 0 8px 0;
            margin: 16px 0;
            position: relative;
			font-weight: 700;
			&::before{
				content: "";
                width: 48px;
                height: 1px;
				background: #d29f31;
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
        .traning-traner-gorld{
            li{
                list-style: none;
                @include clum(3,30%,5%);
                @media screen and (max-width:768px){
                    width: 100%;
                    margin: 0 0 24px!important;
                }
                figure{
                    img{
                        width: 100%;
                    }
                }
                dl{
                    dt{
                        &.gorld{
                            &:after{
                                background:  #d29f31 ;
                            }
                        }
                        font-size: 24px;
                        font-weight: 700;
                        text-align: center;
                        margin: 16px 0 0;
                        @media screen and (max-width:768px){
                            margin: 12px 0 0;
                            font-size: 18px;
                            line-height: normal;
                        }
                        &:after{
                            content: '';
                            width: 48px;
                            height: 1px;
                            background: $red;
                            margin: 16px auto;
                            display: block;
                            @media screen and (max-width:768px){
                                margin: 10px auto;
                            }
                        }
                    }
                    dd{
                        text-align: center;
                    }
                }
                &:last-child(){
                    @media screen and (max-width:768px){
                        margin: 0!important;
                    }
                }
            }
        }
	}
    .traning-traner-list{
        border: #EEE 8px solid;
        padding: 25px;
		&.border-none{
			border: none;
		}
		&.gorld{
			border: #d29f31 1px solid !important;		
		} 
        li{
            list-style: none;
            @include clum(3,30%,5%);
            @media screen and (max-width:768px){
                width: 100%;
                margin: 0 0 24px!important;
            }
            figure{
                img{
                    width: 100%;
                }
            }
            dl{
                dt{
					&.gorld{
						&:after{
							background:  #d29f31 ;
						}
					}
                    font-size: 24px;
                    font-weight: 700;
                    text-align: center;
                    margin: 16px 0 0;
                    @media screen and (max-width:768px){
                        margin: 12px 0 0;
                        font-size: 18px;
                        line-height: normal;
                    }
                    &:after{
                        content: '';
                        width: 48px;
                        height: 1px;
                        background: $red;
                        margin: 16px auto;
                        display: block;
                        @media screen and (max-width:768px){
                            margin: 10px auto;
                        }
                    }
                }
                dd{
                    text-align: center;
                }
            }
            &:last-child(){
                @media screen and (max-width:768px){
                    margin: 0!important;
                }
            }
        }
    }
    .fitness-flex-list{
        @include flex();
        @media screen and (max-width:768px){
            flex-direction: column;
        }
        &.three{
            li{
                width: 32%;
                @media screen and (max-width:768px){
                    width: 100%;
                }
            }
        }
        li{
            list-style: none;
            width: 48.5%;
            background: #FFF;
            border: rgba(#000,.12) 8px solid;
            padding: 25px;
			margin: 0 0 32px;
            @media screen and (max-width:768px){
                width: 100%;
            }
            figure{
                img{
                    width: 100%;
                }
            }
            dl{
                dt{
                    text-align: center;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 1.5;
                    margin: 24px 0 15px;
                    @media screen and (max-width:768px){
                        font-size: 18px;
                    }
                    &:before{
                        content: attr(data-eng);
                        color: $red;
                        display: block;
                    }
                }
            }
        }
    }
	.fitness-price-list{
		@include flex();
		li{
			width: 48.5%;
			margin: 0 0 32px;
			list-style: none;
			background: #FFF;
			padding: 25px;
            @media screen and (max-width:768px){
                width: 100%;
            }
			&.wide{
				width: 100%;
			}
			&.border-red{
					border: 1px solid $red;
				}
			dl.price{
				font-weight: 700;
				dt{
					font-size: 20px;
					font-weight: 700;
					margin: 0 0 12px;
					padding: 0 0 12px;
					border-bottom: $border 1px solid;
                    @media screen and (max-width:768px){
                        text-align: center;
                    }
				}
				dd{
					font-size: 16px;
                    @media screen and (max-width:768px){
                        text-align: center;
                    }
					strong{
						display: inline-block;
						font-size: 40px;
						line-height: 1;
						color: $red;
						margin: 0 8px;
                        @media screen and (max-width:768px){
                            font-size: 32px;
                        }
					}
				}
			}
			dl.desc{
				margin: 16px 0 0;
				dt{
					background: $normal;
					color: #FFF;
					padding: 4px 24px;
					display: inline-block;
                    @media screen and (max-width:768px){
                        width: 100%;
                        text-align: center;
                    }
				}
				dd{
					margin: 8px 0 0;
				}
			}
		}
	}
	p.mini{
		font-size: 12px;
	}
	dl.register{
		background: #FFF;
		padding: 25px;
		margin: 0 0 24px;
		dt{
			font-size: 20px;
			font-weight: 700;
			margin: 0 0 12px;
		}
		dd{
			&:before{
				content: '■';
				color: $normal;
				display: inline-block;
				margin: 0 8px 0 0;
			}
		}
	}
	.atten{
		li{
			margin: 0 0 16px;
			font-size: 13px;
			&:last-child{
				margin: 0;
			}
		} 
	} 
	.fig{
		width: 100%;  
		background: #fff;
    	margin: 0 0 4%;
		padding: 16px;
		border: 4px solid rgba(000,000,000,.8); 
		img{
			width: 40%;
			margin: 0 30%;
            @media screen and (max-width:768px){
                width: 80%;
                margin: 0 10%;
            } 
		}
	}
	.yoga{ 
        padding: 40px;
        border: rgba(0,0,0,.12) 8px solid;
        margin-top: 24px;
        @media screen and (max-width:768px){
            padding: 24px; 
        }
		.yoga-wraper{ 
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			li{
				width: 40%;
                @media screen and (max-width:768px){
                    width: 100%;
				}
				&:last-child{
					dl{
						margin-bottom: 0;
					}
				} 
				dl{
                    @media screen and (max-width:768px){
                        margin-bottom: 24px;
                    }				
					dt{
                        figure{
                            img{
                                width: 100%;
                            }
                        }
					}
					dd{
					&.time{
                        text-align: center;
                        font-size: 34px;
						font-weight: bold; 
					}
					}
				}
			}
		}
	}
}
